<template>
  <v-app id="app">
    <router-view />
    <we-snack-bar />
  </v-app>
</template>

<script>
import "styles";

export default {
  mounted() {
    if (this.$route.query) {
      this.$store.commit("setQueryParams", this.$route.query);
    }
  }
};
</script>

<style lang="scss"></style>
