<i18n>
{
  "en": {
    "address": "Address",
    "address-input-1": "Zip Code",
    "address-input-2": "Street",
    "address-input-3": "Number",
    "address-input-4": "District",
    "address-input-5": "Complement",
    "address-input-6": "City",
    "address-input-7": "State",
    "address-input-8": "Country",
    "update": "Update"
  },
  "pt-BR": {
    "address": "Endereço",
    "address-input-1": "CEP",
    "address-input-2": "Rua",
    "address-input-3": "Número",
    "address-input-4": "Bairro",
    "address-input-5": "Complemento",
    "address-input-6": "Cidade",
    "address-input-7": "Estado",
    "address-input-8": "País",
    "update": "Atualizar"
  }
}
</i18n>

<template>
  <modal-action @close="close" :columns="2">
    <template #view>
      <div class="view">
        <div class="modal">
          <div class="modal-header">
            <div class="name">
              <div class="icon">
                <font-awesome-icon icon="globe-americas" />
              </div>
              {{ $t("address") }}
            </div>
            <div class="options">
              <we-icon-button class="big-mobile" icon="times" @click="close" />
            </div>
          </div>
          <div class="modal-body">
            <v-form class="fields" ref="newDeal">
              <we-input
                v-model="address.zipcode"
                type="text"
                :label="$t('address-input-1')"
                :mask="$root.$t('zipcode-mask')"
                @blur="getCep($event)"
              />
              <we-input
                v-model="address.street"
                type="text"
                :label="$t('address-input-2')"
                :loading="cepLoading"
              />
              <we-input
                v-model="address.number"
                :mask="'######'"
                type="text"
                :label="$t('address-input-3')"
              />
              <we-input
                v-model="address.block"
                type="text"
                :label="$t('address-input-4')"
                :loading="cepLoading"
              />
              <we-input
                v-model="address.note"
                type="text"
                :label="$t('address-input-5')"
              />
              <we-input
                v-model="address.city"
                type="text"
                :label="$t('address-input-6')"
                :loading="cepLoading"
              />
              <we-input
                v-model="address.state"
                type="text"
                :label="$t('address-input-7')"
                :loading="cepLoading"
              />
              <we-input
                v-model="address.country"
                type="text"
                :label="$t('address-input-8')"
                :loading="cepLoading"
              />
            </v-form>
          </div>
          <div class="bottom">
            <we-button
              class="green"
              :text="$t('update')"
              icon="check"
              @click="update"
            />
          </div>
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      cepLoading: false
    };
  },
  methods: {
    ...mapActions(["cepRequest"]),
    update() {
      this.order.address = this.address;
      this.close();
    },
    close() {
      this.$emit("close");
    },
    async getCep(number) {
      if (number.length === 9) {
        this.cepLoading = true;
        const cep = await this.cepRequest(number.split("-").join(""));
        this.cepLoading = false;

        if (!cep.data) return;

        this.address.street = cep.data.logradouro;
        this.address.block = cep.data.bairro;
        this.address.city = cep.data.localidade;
        this.address.state = cep.data.uf;
        this.address.country = "Brasil";
      }
    }
  },
  computed: {
    order() {
      return this.$store.getters.getOrder;
    },
    address() {
      return this.order.address;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-body {
    min-height: 250px;
  }
}
</style>
