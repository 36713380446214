<template>
  <button
    :class="iconPosition"
    :disabled="loading"
    class="we-button"
    v-bind="$attrs"
    @click="clickEvent"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="darken"
      size="16"
      width="3"
    ></v-progress-circular>
    <font-awesome-icon v-if="icon && !loading" :icon="icon" />
    <div class="button-text" :class="{ 'only-desktop': mobileText }">
      {{ text }}
    </div>
    <div class="button-text only-mobile">{{ mobileText }}</div>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    mobileText: String,
    icon: [String, Array, Object],
    iconPosition: String,
    loading: Boolean,
    href: String,
    target: {
      type: String,
      default: "_self"
    }
  },
  methods: {
    clickEvent() {
      this.$emit("click");

      if (this.href?.length > 0) {
        window.open(this.href, this.target);
      }
    }
  }
};
</script>

<style lang="scss">
.v-application {
  .we-button {
    text-decoration: none;
    border: none;
    border-radius: 7px;
    font-family: $font;
    font-weight: $medium;
    font-size: 14px;
    line-height: 1.2;
    color: var(--white);
    height: 38px;
    min-height: 38px;
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--text-2);
    transition: 0.2s;
    cursor: pointer;

    &.mobile-icon {
      @include mobile {
        display: grid;
        grid-template-rows: 16px minmax(0, 1fr);
        gap: 2px;
        justify-content: center;
        padding: 6px 8px;
        align-items: center;
        min-width: 38px;
        width: fit-content;

        svg {
          grid-row: 1;
          height: 16px;
          width: 0.875em;
          margin: 0;
          justify-self: center;
        }

        .button-text {
          grid-row: 2;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }

    &.fill {
      width: 100%;
      justify-content: center;
    }

    &.right {
      flex-direction: row-reverse;

      svg {
        margin-right: 0;
        margin-left: 11px;
      }
    }

    &.centered {
      margin: 0 auto;
    }

    .v-progress-circular {
      height: 16px;
      width: 16px;
      margin-left: 7px;
      margin-right: 4px;
    }

    svg {
      height: 16px;
      width: 16px;
      color: var(--white);
      margin-right: 11px;
    }

    &.primary {
      background: var(--primary);
      color: var(--white);
      transition: 0.2s;

      &.shadow {
        box-shadow: 0px 1px 20px rgba(var(--primary-rgb), 0.4);
      }

      &:hover {
        background: var(--primary-hover) !important;
      }
    }

    &.green,
    &.success {
      background: var(--green);
      color: var(--white);
      transition: 0.2s;

      &.shadow {
        box-shadow: 0px 1px 20px rgba(var(--green-rgb), 0.4);
      }

      &:hover {
        background: var(--green-hover) !important;
      }
    }

    &.red,
    &.error {
      background: var(--red);
      color: var(--white);
      transition: 0.2s;

      &.shadow {
        box-shadow: 0px 1px 20px rgba(var(--red-rgb), 0.4);
      }

      &:hover {
        background: var(--red-hover) !important;
      }
    }

    &.black {
      background: var(--black) !important;
      color: var(--white) !important;
      transition: 0.2s;

      &.shadow {
        box-shadow: 0px 1px 20px rgba(var(--black-rgb), 0.4);
      }

      &:hover {
        background: var(--black-hover) !important;
      }
    }

    &.primary-light {
      background: var(--primary-bg);
      color: var(--primary);
      transition: 0.2s;

      &.shadow {
        box-shadow: 0px 1px 20px rgba(var(--primary-rgb), 0.4);
      }

      svg {
        color: var(--primary);
      }

      &:hover {
        background: var(--action-hover) !important;
        color: var(--primary);
      }
    }

    &.inherit {
      background: var(--inherit) !important;
      color: var(--primary);
      transition: 0.2s;

      &.shadow {
        box-shadow: 0;
      }

      &.green {
        color: var(--green);

        svg {
          color: var(--green);
        }
      }

      svg {
        color: var(--primary);
      }

      &:hover {
        color: var(--text-1) !important;
        background: var(--inherit) !important;

        svg {
          color: var(--text-1);
        }
      }

      &.black {
        color: var(--text-1) !important;

        svg {
          color: var(--text-1) !important;
        }

        &:hover {
          color: var(--primary) !important;

          svg {
            color: var(--primary) !important;
          }
        }
      }
    }

    &.big {
      height: 60px;
      min-height: 60px;
    }

    &.small {
      height: 30px;
      min-height: 30px;
      border-radius: 5px;
      font-weight: $medium;
    }

    &:hover {
      transition: 0.2s;
      color: var(--white);
      box-shadow: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    &.disabled {
      background: var(--action) !important;
      color: var(--text-1);

      svg {
        color: var(--text-1);
      }

      &:hover {
        background: var(--action-hover) !important;
      }
    }

    &:disabled {
      background: var(--action) !important;
      color: var(--text-2);
      cursor: initial;

      svg {
        color: var(--text-2);
      }

      &:hover {
        background: var(--action) !important;
        color: var(--text-2);
      }
    }
  }
}
</style>
