import Vue from "vue";
import store from "@/store";
import i18n from "@/plugins/i18n.js";

const locales = {
  en: {
    created: "created",
    updated: "updated",
    deleted: "deleted",
    "sent-file": "sent a file",
    "deleted-file": "deleted a file",
    "the-contact": "Contact",
    "the-lead": "lead",
    "the-company": "company",
    "the-user": "user",
    "the-bill": "bill",
    yes: "yes",
    no: "no",
    contacts: "Contacts",
    companies: "Companies",
    leads: "Leads",
    users: "Users",
    workflows: "Workflows",
    products: "Products",
    bills: "Bills",
    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    and: "and"
  },
  "pt-BR": {
    created: "criou",
    updated: "atualizou",
    deleted: "deletou",
    "sent-file": "enviou um arquivo",
    "deleted-file": "deletou um arquivo",
    "the-contact": "o contato",
    "the-lead": "a oportunidade",
    "the-company": "a empresa",
    "the-user": "o usuário",
    "the-bill": "o lançamento",
    yes: "sim",
    no: "não",
    contacts: "Contatos",
    companies: "Empresas",
    leads: "Oportunidades",
    users: "Usuários",
    workflows: "Pipelines",
    products: "Produtos",
    bills: "Lançamentos",
    day: "dia",
    days: "dias",
    hour: "hora",
    hours: "horas",
    and: "e"
  }
};

export default locales;

Vue.filter("dateTime", function(value) {
  if (!value) return "";

  const date = new Date(value);

  return i18n.d(date, "shortDateTime");
});

Vue.filter("cpf", function(value) {
  if (!value) return "";
  value = value.toString();

  let dot = ".";
  let position = 3;
  let output = [value.slice(0, position), dot, value.slice(position)].join("");
  position = 7;
  output = [output.slice(0, position), dot, output.slice(position)].join("");
  position = 11;
  output = [output.slice(0, position), "-", output.slice(position)].join("");

  return value.includes(".") ? value : output;
});

Vue.filter("logAction", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value === "create") {
    text = i18n.t("created");
  } else if (value === "update") {
    text = i18n.t("updated");
  } else if (value === "delete") {
    text = i18n.t("deleted");
  } else if (value === "picture uploaded") {
    text = i18n.t("sent-file");
  } else if (value === "picture removed") {
    text = i18n.t("deleted-file");
  }

  return text;
});

Vue.filter("logEntity", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value === "contact") {
    text = i18n.t("the-contact");
  } else if (value === "deal") {
    text = i18n.t("the-lead");
  } else if (value === "company") {
    text = i18n.t("the-company");
  } else if (value === "user") {
    text = i18n.t("the-user");
  } else if (value === "bill") {
    text = i18n.t("the-bill");
  }

  return text;
});

Vue.filter("epochToDate", function(value) {
  if (!value) return "";
  value = parseInt(value);

  let date = new Date(value)
    .toISOString()
    .split(".")[0]
    .split("T")
    .join(" ");

  return date;
});

Vue.filter("fileSize", function(value) {
  if (!value) return "";
  value = value.toString();

  return value.slice(0, -3) + "kb";
});

Vue.filter("fileExtension", function(value) {
  if (!value) return "";
  value = value.toString();

  const ext = value.split(".");

  return ext[ext.length - 1].toLowerCase();
});

Vue.filter("moreThanOne", function(value) {
  if (!value) return "";

  return value > 1 ? `+ ${value - 1}` : "";
});

Vue.filter("convertRole", function(value) {
  if (!value) return "";
  value = value.toString();

  let text = "";

  if (value.length > 0) {
    let roles = store.getters.getUserRoleList || [];

    text = roles.find(e => e.value === value).text;
  }

  return text;
});

Vue.filter("booleanToString", function(value) {
  if (!value) return "";

  let text = "";

  if (value) {
    text = i18n.t("yes");
  } else {
    text = i18n.t("no");
  }

  return text;
});

Vue.filter("dealTaskDate", function(value) {
  if (!value) return "";
  const date = new Date(value);

  return i18n.d(date, "dealTaskDate");
});

Vue.filter("brDateText", function(value) {
  if (!value) return "";
  let date = new Date(value);
  date.setDate(date.getDate() + 1);

  return i18n.d(date, "longOnlyDate");
});

Vue.filter("brDateTextWithoutYear", function(value) {
  if (!value) return "";
  let date = new Date(value);
  date.setDate(date.getDate() + 1);

  return i18n.d(date, "dayAndMonth");
});

Vue.filter("dealTaskHour", function(value) {
  if (!value) return "";
  const date = new Date(value);
  return i18n.d(date, "hoursAndMinutes");
});

Vue.filter("valueToReais", function(value) {
  let price = 0;

  if (value) {
    price = Number(value);
  }

  return i18n.n(price, "currency");
});

Vue.filter("entityToPtBr", function(value) {
  if (!value) return "";

  if (value === "contacts") {
    return i18n.t("contacts");
  } else if (value === "companies" || value === "companys") {
    return i18n.t("companies");
  } else if (value === "deals") {
    return i18n.t("deals");
  } else if (value === "users") {
    return i18n.t("users");
  } else if (value === "pipelines") {
    return i18n.t("workflows");
  } else if (value === "products") {
    return i18n.t("products");
  } else if (value === "bills") {
    return i18n.t("bills");
  }

  return "";
});

Vue.filter("billStatusToPtBr", function(value) {
  if (!value) return "";

  if (store.getters.getBillStatusList) {
    return store.getters.getBillStatusList.find(e => e.value === value).text;
  }

  return "";
});

Vue.filter("billTypesToPtBr", function(value) {
  if (!value) return "";

  if (store.getters.getBillTypesList) {
    return store.getters.getBillTypesList.find(e => e.value === value).text;
  }

  return "";
});

Vue.filter("convertToCSV", function(value) {
  if (!value) return "";

  let array = typeof objArray != "object" ? JSON.parse(value) : value;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
});

Vue.filter("hoursToDaysAndHours", function(value) {
  let text = "0h";

  if (value) {
    let winDays = Math.floor(Number(value) / 24);
    let winHours = Number(value) % 24;
    text = winDays > 0 ? `${winDays}d${winHours}h` : `${winHours}h`;
  }

  return text;
});

Vue.filter("hoursToDaysAndHoursExtended", function(value) {
  let text = "0 horas";

  if (value) {
    const winDays = Math.floor(Number(value) / 24);
    const winHours = Number(value) % 24;

    const winDaysExtended =
      winDays != 1
        ? `${winDays} ${i18n.t("days")}`
        : `${winDays} ${i18n.t("day")}`;
    const winHoursExtended =
      winHours != 1
        ? `${winHours} ${i18n.t("hours")}`
        : `${winHours} ${i18n.t("hour")}`;

    text =
      winDays > 0
        ? `${winDaysExtended} ${i18n.t("and")} ${winHoursExtended}`
        : `${winHoursExtended}`;
  }

  return text;
});

Vue.filter("isJsonString", function(value) {
  if (!value) return false;

  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
});

Vue.filter("isUrl", function(string) {
  if (!string) return false;

  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
});
