import Vue from "vue";

import WeDropFile from "@/components/interface/WeDropFile.vue";

Vue.component("we-drop-file", WeDropFile);

import {
  WeSnackBar,
  WeImageUpload,
  WeIconTooltip,
  WeFileUpload,
  WeBalloon,
  WeButton,
  WeBlock,
  WeIconButton,
  WePictureButton,
  WeProductIcon,
  WeRoundedPicture,
  WeInput,
  WeLoadingOverflow,
  WeTextTooltip,
  WeNavX,
  WeShadow,
  WeAction,
  WeCardIcon,
  WeSteps,
  WeDataField,
  WeTable,
  WeTable2,
  ModalAction
} from "ui";

Vue.component("we-image-upload", WeImageUpload);
Vue.component("we-icon-tooltip", WeIconTooltip);
Vue.component("we-file-upload", WeFileUpload);
Vue.component("we-block", WeBlock);
Vue.component("we-card-icon", WeCardIcon);
Vue.component("we-steps", WeSteps);
Vue.component("we-data-field", WeDataField);
Vue.component("we-table", WeTable);
Vue.component("we-table-2", WeTable2);
Vue.component("we-snack-bar", WeSnackBar);
Vue.component("we-icon-button", WeIconButton);
Vue.component("we-picture-button", WePictureButton);
Vue.component("we-product-icon", WeProductIcon);
Vue.component("we-rounded-picture", WeRoundedPicture);
Vue.component("we-balloon", WeBalloon);
Vue.component("we-button", WeButton);
Vue.component("we-input", WeInput);
Vue.component("we-loading-overflow", WeLoadingOverflow);
Vue.component("we-text-tooltip", WeTextTooltip);
Vue.component("we-nav-x", WeNavX);
Vue.component("we-shadow", WeShadow);
Vue.component("we-action", WeAction);
Vue.component("modal-action", ModalAction);
