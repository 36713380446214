<template>
  <div class="we-steps" :class="{ small }">
    <div
      class="step"
      v-for="(step, i) in steps"
      :key="i"
      :class="{ prev: activeStep > i, current: activeStep === i }"
    >
      <v-tooltip top :disabled="!small">
        <template v-slot:activator="{ on, attrs }">
          <div class="number" v-bind="attrs" v-on="on">
            <font-awesome-icon
              v-if="step.icon"
              class="icon"
              :icon="step.icon"
            />
            <span v-else class="number--text">
              {{ i + 1 }}
            </span>
          </div>
        </template>
        <span>{{ step.text || step }}</span>
      </v-tooltip>
      <div class="text">
        <span class="text-overflow-center">{{ step.text || step }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Array,
    activeStep: Number,
    small: Boolean
  }
};
</script>

<style lang="scss">
.we-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.small {
    .step {
      &::before {
        top: 16px;
      }
      .number {
        height: 32px;
        width: 32px;

        .icon {
          height: 14px;
          width: 14px;
        }
      }
      .text {
        display: none;
      }
    }
  }
  .step {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    &::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      background: var(--line);
      top: 20px;
      left: 0;
    }

    &:last-child {
      flex: initial;
      align-items: flex-end;
    }

    &.current {
      .number {
        border-color: var(--primary);
        background: var(--primary-bg);
        color: var(--primary);
      }
    }

    &.prev {
      &::before {
        background: var(--primary);
      }
      .number {
        border-color: var(--primary);
        background: var(--primary);
        color: var(--background-1);
      }
    }

    .number {
      position: relative;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: $semibold;
      color: var(--text-2);
      border-radius: 40px;
      border: 1px solid var(--line);
      z-index: 1;
      background: var(--background-1);

      .icon {
        height: 18px;
        width: 18px;
      }
    }

    .text {
      font-size: 10px;
      text-transform: uppercase;
      color: var(--text-2);
      font-weight: $semibold;
      margin-top: 6px;
      max-width: 40px;
      text-align: center;
      width: 100%;

      .text-overflow-center {
        margin-left: -100%;
        margin-right: -100%;
        text-align: center;
      }
    }
  }
}
</style>
