<template>
  <div class="we-image-upload">
    <slot name="input" />
    <div class="name">
      {{ title }}
    </div>
    <div class="image">
      <we-loading-overflow class="small" :loading="loading" />
      <div class="click-box" @click="$emit('click')">
        <img v-if="image" :src="image" :alt="title" />
        <div v-else class="message">
          <font-awesome-icon icon="file-upload" />
          Anexar imagem
        </div>
      </div>
    </div>
    <div class="upload-info">
      {{ info }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    image: String,
    info: String,
    loading: Boolean
  }
};
</script>

<style lang="scss">
.we-image-upload {
  .name {
    text-align: center;
    font-size: 14px;
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 5px;
  }

  .image {
    height: 97px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden;

    .click-box {
      background: #fff;
      height: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: var(--action);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }

    .message {
      font-size: 14px;
      font-weight: $medium;
      color: var(--text-2);
      display: flex;
      align-items: center;

      svg {
        height: 18px;
        width: auto;
        color: var(--text-2);
        margin-right: 12px;
        position: relative;
        top: -1px;
      }
    }
  }

  .upload-info {
    text-align: center;
    font-size: 10px;
    color: var(--text-2);
  }
}
</style>
