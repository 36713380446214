<template>
  <v-menu
    rounded
    bottom
    left
    offset-y
    :content-class="`we-balloon ${disabled ? 'disabled' : ''}`"
    :close-on-content-click="closeOnContentClick"
    @input="clickEvent"
  >
    <template v-slot:activator="{ attrs, on }">
      <div class="menu-btn" v-bind="attrs" v-on="on" @click.prevent>
        <slot name="action" />
      </div>
    </template>
    <v-list :style="{ maxHeight }">
      <v-subheader v-if="title">{{ title }}</v-subheader>
      <slot name="balloon" />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: "100%"
    },
    title: String,
    disabled: Boolean
  },
  data() {
    return {
      opened: false
    };
  },
  methods: {
    clickEvent(active) {
      this.$emit("input", active);
    },
    openBalloon() {
      this.opened = true;
    },
    closeBalloon() {
      this.opened = false;
    }
  }
};
</script>

<style lang="scss">
.we-balloon {
  background: var(--background-1);
  &:hover {
    background: var(--background-1);
  }

  &.disabled {
    .balloon-item,
    .reset {
      pointer-events: none;
    }
  }

  .we-input {
    margin-bottom: 8px;
  }

  .item {
    max-height: 128px;
    padding: 0 8px;
    align-items: center;
    margin-right: 18px;
    position: relative;
    cursor: pointer;
    background: var(--background-1);

    &:last-child {
      margin: 0;
    }

    &:hover {
      &::before {
        display: block;
      }
    }
    .balloon-item {
      display: flex;
      &.dropdown-item {
        &:hover {
          background: var(--background-1);
        }
        &.active {
          color: black;
        }
      }
    }

    &.active {
      .name,
      .number,
      .icon {
        color: var(--text-1);
      }
    }
    .icon {
      margin-right: 8px;
      position: relative;
      bottom: -1px;
      color: var(--text-2);
    }

    .name {
      font-weight: $semibold;
      font-size: 14px;
      color: var(--text-2);
    }

    .number {
      margin-left: 8px;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      border-radius: 4px;
      font-size: 13px;
      font-weight: $semibold;
      background: var(--action);
      color: var(--text-2);
    }
  }

  &.rounded {
    border-radius: 7px !important;
  }

  .v-list {
    padding: 10px;

    .v-subheader {
      max-width: 260px;
      text-align: center;
      margin-bottom: 12px;
    }
  }

  .reset {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $semibold;
    letter-spacing: 1px;
    color: var(--primary);
    cursor: pointer;
    padding-bottom: 8px;

    &:hover {
      color: var(--primary-hover);
    }
  }

  .balloon-item {
    display: flex;
    align-items: center;
    padding: 0 18px;
    height: 38px;
    font-weight: $medium;
    font-size: 14px;
    color: var(--text-2);
    cursor: pointer;
    width: calc(100% + 20px);
    margin-left: -10px;
    position: relative;

    &.has-submenu {
      padding-right: 34px;
    }

    &.active {
      background-color: var(--primary-bg);
      color: var(--primary);
    }

    &.red--text {
      svg {
        color: var(--red);
      }
    }

    .rounded-picture {
      margin-right: 8px;
    }

    .number {
      background: var(--action);
      color: var(--text-2);
      font-weight: $semibold;
      line-height: 1;
      padding: 3px 6px;
      border-radius: 4px;
      font-size: 13px;
      margin-left: 8px;
    }

    .icon {
      width: 24px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      svg {
        height: 18px;
        width: 18px;
        margin: 0;
      }
    }

    .submenu-icon {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      position: absolute;
      right: 10px;
      top: 12px;

      svg {
        height: 14px;
        width: auto;
        margin: 0;
      }
    }

    svg {
      margin-right: 14px;
      height: 18px;
      width: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--primary);
      color: var(--background-1) !important;

      svg {
        color: var(--background-1) !important;
      }

      .number {
        background: var(--primary-bg);
        color: var(--background-1);
      }
    }
  }
}
</style>
