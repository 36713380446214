<i18n>
{
  "en": {
    "more": "More"
  },
  "pt-BR": {
    "more": "Mais"
  }
}
</i18n>

<template>
  <nav class="we-nav-x" :id="hashId">
    <ul class="visible-links">
      <v-menu
        bottom
        offset-y
        eager
        max-height="400px"
        :content-class="`we-nav-x-more ${hashId}`"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="item-link more"
            :class="{ active: moreActive }"
            v-bind="attrs"
            v-on="on"
          >
            <span class="name">
              {{ $t("more") }}
            </span>
            <div class="icon icon-right">
              <font-awesome-icon icon="chevron-down" />
            </div>
          </div>
        </template>

        <v-list class="hidden-links hidden">
          <v-list-item
            class="hidden-item hide"
            v-for="(item, index) in items"
            :key="item.id"
            :data-key="item.id"
          >
            <div
              class="item-link"
              :class="{ active: index === activeIndex }"
              @click="
                $emit('select', index);
                updateMoreActive();
              "
            >
              <font-awesome-icon v-if="item.icon" :icon="item.icon" />
              <span class="name">{{ item.name }}</span>
              <span class="number" v-if="item.number">{{ item.number }}</span>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
      <li
        class="visible-item"
        v-for="(item, index) in items"
        :key="item.id"
        :data-key="item.id"
        :cy="`${cy}-${index}`"
      >
        <div
          class="item-link"
          :class="{ active: index === activeIndex }"
          @click="
            $emit('select', index);
            updateMoreActive();
          "
        >
          <font-awesome-icon v-if="item.icon" :icon="item.icon" />
          <span class="name">{{ item.name }}</span>
          <span class="number" v-if="item.number">{{ item.number }}</span>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    items: Array,
    activeIndex: Number,
    cy: {
      type: String,
      default: "item"
    }
  },
  data() {
    return {
      moreActive: false,
      breaks: [],
      oldWidth: 9999999999
    };
  },
  computed: {
    hashId() {
      const s = "abcdefghijklmnopqrstuvwxyz";
      return Array(5)
        .join()
        .split(",")
        .map(function() {
          return s.charAt(Math.floor(Math.random() * s.length));
        })
        .join("");
    }
  },
  methods: {
    updateMoreActive() {
      setTimeout(() => {
        const hiddenLinks = document.querySelectorAll(
          `.we-nav-x-more.${this.hashId} .hidden-links .hidden-item`
        );

        let itemsActive = 0;

        hiddenLinks.forEach(e => {
          if (!e.classList.contains("hide")) {
            const hasActive = e
              .querySelector(".item-link")
              .classList.contains("active");

            if (hasActive) itemsActive++;
          }
        });

        this.moreActive = itemsActive > 0;
      }, 10);
    },
    updateNav(event) {
      const $nav = document.querySelector(`#${this.hashId}.we-nav-x`);
      const $btn = document.querySelector(`#${this.hashId}.we-nav-x .more`);
      const $vlinks = document.querySelector(
        `#${this.hashId}.we-nav-x .visible-links`
      );
      const $hlinks = document.querySelector(
        `.we-nav-x-more.${this.hashId} .hidden-links`
      );

      const availableSpace = $btn?.classList.contains("hidden")
        ? $nav?.clientWidth
        : $nav?.clientWidth - $btn?.clientWidth;

      if ($nav?.clientWidth > this.oldWidth) {
        const difference = this.breaks.length > 1 ? 0 : $btn?.clientWidth;

        if (
          availableSpace >=
          this.breaks[this.breaks.length - 1] - difference + 20
        ) {
          //Move the item to the visible list
          const nodes = $hlinks.querySelectorAll(".hidden-item:not(.hide)");
          const firstHidden = nodes[0];
          firstHidden?.classList.add("hide");
          const key = firstHidden?.dataset.key;
          const visibleElement = document.querySelector(
            `.visible-item[data-key='${key}']`
          );
          visibleElement?.classList.remove("hide");
          this.breaks.pop();
        }
      } else if ($vlinks?.offsetWidth > availableSpace) {
        // Record the width of the list

        if (!this.breaks.some(e => e === $vlinks?.offsetWidth)) {
          this.breaks = [...this.breaks, $vlinks?.offsetWidth];
        }

        // Move item to the hidden list
        const nodes = $vlinks.querySelectorAll(".visible-item:not(.hide)");
        const lastVisible = nodes[nodes.length - 1];
        lastVisible?.classList.add("hide");
        const key = lastVisible?.dataset.key;
        const hiddenElement = document.querySelector(
          `.hidden-item[data-key='${key}']`
        );
        hiddenElement?.classList.remove("hide");

        if (event === "mount") {
          this.updateNav("mount");
        }
      }

      if (this.breaks.length < 1) {
        $btn?.classList.add("hidden");
      } else {
        $btn?.classList.remove("hidden");
      }

      this.oldWidth = $nav?.clientWidth;
    }
  },
  mounted() {
    if (document.body.clientWidth > 768) {
      window.addEventListener("resize", this.updateNav);
      this.breaks = [];

      setTimeout(() => {
        this.updateNav("mount");
        this.updateMoreActive();
      }, 1);
    }
  }
};
</script>

<style lang="scss">
.we-nav-x {
  position: relative;
  margin-left: 50px;

  @include mobile {
    margin-left: 0;
    padding: 0;
    height: 47px;
    margin-bottom: -1px;
  }

  .item-link {
    height: 36px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include mobile {
      height: 100%;
    }

    &:hover {
      .name {
        color: var(--text-1);
      }
      &::before {
        display: block;
      }
    }

    &.active {
      .name,
      .number,
      .icon {
        color: var(--text-1);
      }

      &::before {
        display: block;
        background: var(--orange);
      }
    }

    &::before {
      content: "";
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      border-radius: 3px;
      width: 100%;
      background: var(--action-hover);
    }

    .icon {
      margin-left: 8px;

      &.icon-right {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    svg {
      position: relative;
      bottom: 1px;
      color: var(--text-2);
      margin-right: 8px;
      height: 13px;
    }

    .name {
      font-weight: $semibold;
      font-size: 14px;
      color: var(--text-2);
      white-space: nowrap;
    }

    .nav-label {
      padding: 0 4px;
      background: var(--green);
      text-transform: uppercase;
      font-weight: $semibold;
      font-size: 9px;
      line-height: 14px;
      color: var(--background-1);
      border-radius: 2px;
      margin-left: 6px;
    }

    .number {
      margin-left: 8px;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      border-radius: 4px;
      font-size: 13px;
      font-weight: $semibold;
      background: var(--action);
      color: var(--text-2);
    }
  }

  .more {
    position: absolute;
    height: 100%;
    right: -72px;
    bottom: 0;
    margin: 0;

    @include mobile {
      display: none;
    }
  }

  .hamburger {
    position: relative;
    width: 32px;
    height: 4px;
    background: var(--background-1);
    margin: auto;

    @include mobile {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 32px;
      height: 4px;
      background: var(--background-1);
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }

  .visible-links {
    display: flex;
    width: fit-content;
    list-style: none;
    position: relative;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @include mobile {
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      align-items: flex-end;
    }

    li {
      margin-right: 8px;

      &:nth-child(3) {
        @include mobile {
          margin-left: 15px;
        }
      }

      @include mobile {
        height: 100%;
      }
      &:not(.hide) {
        &:last-child {
          margin: 0;
        }
      }
      &.hide {
        display: none;
      }
    }
  }

  .hidden-links {
    position: absolute;
    right: 0px;
    top: 100%;

    li {
      display: block;

      &.hide {
        display: none;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
}
</style>
