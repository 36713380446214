<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!isDesktop"
    :open-delay="delay"
    content-class="we-icon-tooltip-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="we-icon-tooltip"
        :class="componentClass"
        v-bind="attrs"
        v-on="on"
      >
        <font-awesome-icon :class="color" :icon="icon" />
      </div>
    </template>
    <span>
      <slot name="text" />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "we-icon-tooltip",
  props: {
    icon: [String, Array, Object],
    direction: {
      type: String,
      default: "top"
    },
    color: {
      type: String,
      default: ""
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  computed: {
    componentClass() {
      if (this.$options._parentVnode.data.staticClass) {
        return this.$options._parentVnode.data.staticClass;
      }

      return "";
    }
  }
};
</script>

<style lang="scss">
.we-icon-tooltip {
  display: flex;
  align-items: center;
  cursor: default;
  svg {
    height: 18px;
    width: auto;
    color: var(--text-2);
  }
}

.we-icon-tooltip-content {
  .deal-time {
    text-align: center;
    > div {
      padding: 8px 0;
    }

    .stage-timeout {
      border-bottom: 1px solid var(--line);
    }
  }

  .deal-tasks {
    .task {
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        color: var(--text-3);
        height: 18px;
        justify-self: center;
      }

      .task-content {
        .task-name {
          font-size: 13px;
        }

        .time-remaining {
          margin-top: 2px;
          font-size: 12px;
          color: var(--text-2);
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .remaining {
            margin-right: 8px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
</style>
