<template>
  <div class="we-action" @click="$emit('click')">
    {{ text }}
    <slot v-if="!text" name="text" />
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
};
</script>

<style lang="scss">
.we-action {
  height: 23px;
  border-radius: 3px;
  background: var(--action);
  padding: 1px 10px;
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  font-weight: $regular;
  color: var(--text-2);
  display: flex;
  align-items: center;

  &:hover {
    background: var(--action-hover);
  }

  &.active {
    background: var(--text-2);
    color: var(--background-1);

    svg {
      color: var(--background-1);
    }
  }

  svg {
    height: 13px;
    width: auto;
    margin-right: 4px;
  }
}
</style>
