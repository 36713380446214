import Vue from "vue";

Vue.use({
  install(Vue) {
    //Deep merge with JS: https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
    const merge = (target, source) => {
      for (const key of Object.keys(source)) {
        if (source[key] instanceof Object)
          Object.assign(source[key], merge(target[key], source[key]));
      }
      Object.assign(target || {}, source);
      return target;
    };
    Vue.prototype.$deepMerge = merge;
  }
});
