<template>
  <div class="we-block" :class="{ active }">
    <div class="block-body">
      <div class="block-top" v-if="$slots['top']">
        <slot name="top"></slot>
      </div>
      <h2 class="block-title">
        {{ title }}
      </h2>
      <p class="block-description" v-if="description">
        {{ description }}
      </p>
      <slot name="sections"></slot>
    </div>
    <div class="block-bottom" v-if="$slots['bottom']">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    switchLabel: String,
    active: Boolean
  }
};
</script>

<style lang="scss">
.we-block {
  border: 1px solid var(--line);
  border-radius: 8px;

  @include mobile {
    border: none;
  }

  &.toggle {
    background: var(--background-2);

    &:not(.active) * {
      color: var(--text-3);
    }

    .block-section {
      display: none;
    }

    &.active {
      border-color: var(--primary);
      background: var(--background-1);

      .block-section {
        display: block;
      }
    }
  }

  .block-body {
    padding: 24px;
    padding-bottom: 0;

    @include mobile {
      padding: 0;
      padding-top: 16px;
    }

    .block-top {
      margin-bottom: 16px;
    }

    .block-title {
      font-size: 20px;
      font-weight: $semibold;
      margin-bottom: 8px;
    }

    .block-description {
      font-size: 14px;
      margin-bottom: 24px;
    }
    .block-section {
      margin-bottom: 24px;
      &.grid,
      > .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 16px;

        @include mobile {
          grid-template-columns: 1fr;
        }
      }

      &.flex {
        display: flex;
        flex-wrap: wrap;

        & > * {
          margin: 0 8px 8px 0;
        }

        .flex-item {
          height: 38px;
          border-radius: 7px;
          padding: 4px 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            background: var(--background-1-hover);

            .text {
              color: var(--text-1);
            }
          }

          &.active {
            background: var(--primary-bg);
            cursor: pointer;

            .text {
              color: var(--primary);
            }
          }

          .picture {
            height: 100%;
            width: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            img {
              width: 100%;
              object-fit: contain;
              object-position: center center;
            }
          }

          .text {
            font-size: 14px;
            font-weight: $medium;
            color: var(--text-2);
          }
        }
      }
    }
  }

  .block-bottom {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--line);
    .bottom-text {
      font-size: 14px;
      flex: 1;
      min-width: 0;
    }

    .bottom-action {
      flex-shrink: 0;
    }
  }
}
</style>
