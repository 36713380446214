import Vue from "vue";
import VueI18n from "vue-i18n";
import filtersLocales from "@/plugins/filters";

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    hoursAndMinutes: {
      hour: "2-digit",
      minute: "2-digit"
    },
    dayAndMonth: {
      month: "short",
      day: "numeric"
    },
    shortOnlyDate: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    longOnlyDate: {
      month: "long",
      day: "numeric",
      year: "numeric"
    },
    shortDateTime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    },
    dealTaskDate: {
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit"
    }
  },
  "pt-BR": {
    hoursAndMinutes: {
      hour: "2-digit",
      minute: "2-digit"
    },
    dayAndMonth: {
      month: "short",
      day: "numeric"
    },
    shortOnlyDate: {
      month: "numeric",
      day: "numeric",
      year: "numeric"
    },
    longOnlyDate: {
      month: "long",
      day: "numeric",
      year: "numeric"
    },
    shortDateTime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    },
    dealTaskDate: {
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit"
    }
  }
};

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD"
    },
    currencyInt: {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  "pt-BR": {
    currency: {
      style: "currency",
      currency: "BRL"
    },
    currencyInt: {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }
};

const loadLocaleMessages = () => {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
};

const getAllMessages = messagesArr => {
  let messages = {};

  messagesArr.forEach(e => {
    messages = Vue.prototype.$deepMerge(messages, e);
  });

  return messages;
};

const globalMessages = {
  en: {
    "phone-mask": "(##) ###-####",
    "phone-second-mask": "(###) ###-####",
    "zipcode-mask": "##############",
    "cpf-mask": "##################",
    "cnpj-mask": "#####################",
    "date-mask": "####-##-##",
    "bank-agency-mask-1": "###########",
    "bank-agency-mask-2": "###########"
  },
  "pt-BR": {
    "phone-mask": "(##) #####-####",
    "phone-second-mask": "(##) ####-####",
    "zipcode-mask": "#####-###",
    "cpf-mask": "###.###.###-##",
    "cnpj-mask": "##.###.###/####-##",
    "date-mask": "##/##/####",
    "bank-agency-mask-1": "####",
    "bank-agency-mask-2": "####-#"
  }
};

export default new VueI18n({
  locale: localStorage.getItem("we-locale") || "pt-BR",
  fallbackLocale: "pt-BR",
  messages: getAllMessages([
    loadLocaleMessages(),
    filtersLocales,
    globalMessages
  ]),
  dateTimeFormats,
  numberFormats
});
