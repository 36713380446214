import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCog,
  faSackDollar,
  faColumns,
  faHeadset,
  faTruck,
  faCalendarDay,
  faCoins,
  faFilter,
  faBroom,
  faFileCsv,
  faFilePdf,
  faFileCode,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faHandshake,
  faUsers,
  faUserCircle,
  faUserTie,
  faUserTimes,
  faAlignLeft,
  faUserPlus,
  faSearch,
  faArrowsAltV,
  faCheckCircle,
  faPlus,
  faLink,
  faMinus,
  faBolt,
  faPaperclip,
  faEllipsisH,
  faSort,
  faTags,
  faStore,
  faDollarSign,
  faCubes,
  faTimes,
  faUser,
  faSignal,
  faGift,
  faWallet,
  faCircleExclamation,
  faHourglassEnd,
  faPeopleCarryBox,
  faHistory,
  faCaretDown,
  faCheckSquare,
  faExternalLinkAlt,
  faTimesCircle,
  faSyncAlt,
  faSortUp,
  faSortDown,
  faGlobeAmericas,
  faDownload,
  faPrint,
  faEnvelope,
  faTasks,
  faEdit,
  faTag,
  faFolderPlus,
  faSignOutAlt,
  faCamera,
  faCheck,
  faFingerprint,
  faArrowRight,
  faArrowLeft,
  faCube,
  faRobot,
  faChartBar,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faCreditCard,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faClock,
  faSquare,
  faCalendarAlt,
  faIdCard
} from "@fortawesome/free-regular-svg-icons";
import {} from "@fortawesome/free-brands-svg-icons";

library.add(
  faCog,
  faSackDollar,
  faColumns,
  faHeadset,
  faTruck,
  faCalendarDay,
  faCube,
  faRobot,
  faChartBar,
  faCheck,
  faFingerprint,
  faCoins,
  faFilter,
  faBroom,
  faFileCsv,
  faFilePdf,
  faFileCode,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faHandshake,
  faUsers,
  faUserCircle,
  faUserTie,
  faUserTimes,
  faAlignLeft,
  faUserPlus,
  faSearch,
  faArrowsAltV,
  faCheckCircle,
  faPlus,
  faLink,
  faMinus,
  faBolt,
  faPaperclip,
  faEllipsisH,
  faSort,
  faBuilding,
  faTags,
  faStore,
  faDollarSign,
  faCubes,
  faTimes,
  faUser,
  faSignal,
  faGift,
  faWallet,
  faCircleExclamation,
  faHourglassEnd,
  faPeopleCarryBox,
  faHistory,
  faCaretDown,
  faCheckSquare,
  faExternalLinkAlt,
  faTimesCircle,
  faSyncAlt,
  faSortUp,
  faSortDown,
  faGlobeAmericas,
  faDownload,
  faPrint,
  faEnvelope,
  faTasks,
  faEdit,
  faTag,
  faFolderPlus,
  faSignOutAlt,
  faCamera,
  faArrowRight,
  faArrowLeft,
  faBell,
  faCreditCard,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faClock,
  faSquare,
  faCalendarAlt,
  faIdCard
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
