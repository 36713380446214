import Vue from "vue";
import Vuex from "vuex";

import queryParams from "@/store/components/queryParams";
import snackBar from "@/store/components/snackBar";

import cep from "@/store/requests/cep";
import files from "@/store/requests/files";
import orders from "@/store/requests/orders";
import returns from "@/store/requests/returns";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackBar,
    queryParams,
    cep,
    files,
    orders,
    returns
  }
});
