<template>
  <div class="we-table" v-if="columns[0] && columns[0].length > 0">
    <div
      class="table-header"
      :style="{
        gridTemplateColumns: `repeat(${labels.length}, minmax(0, 1fr))`
      }"
    >
      <div class="header-item" v-for="(label, i) in labels" :key="i">
        <we-text-tooltip :text="label" />
      </div>
    </div>
    <div class="table-content">
      <div
        class="table-row"
        v-for="number in columnSize"
        :key="number"
        :style="{
          gridTemplateColumns: `repeat(${labels.length}, minmax(0, 1fr))`
        }"
      >
        <div class="table-col" v-for="(label, i) in labels" :key="i">
          <div
            class="color"
            v-if="colors[number] && i === 0"
            :style="{ background: colors[number] }"
          ></div>
          <we-text-tooltip :text="columns[i][number]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labels: [Array],
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    colors: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    columnSize() {
      let array = [];

      if (this.columns[0]) {
        array = this.columns[0];
      }

      return array.map((e, i) => i);
    }
  },
  methods: {
    getString(string) {
      let value = "";

      if (Array.isArray(string)) {
        value = string;
      }

      return value;
    }
  }
};
</script>

<style lang="scss">
.we-table {
  font-size: 14px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  .table-header {
    display: grid;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--line);

    .header-item {
      font-weight: $medium;
      color: var(--text-2);
      padding-left: 6px;
    }
  }

  .table-content {
    height: 100%;
    flex: 1;
    min-height: 100%;
    overflow-y: auto;
    .table-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      height: 30px;
      align-items: center;
      background: var(--background-1);

      &:nth-child(even) {
        background: var(--background-2);
      }

      .table-col {
        color: var(--text-1);
        padding-left: 6px;
        display: flex;
        align-items: center;

        .color {
          height: 18px;
          width: 5px;
          min-width: 5px;
          border-radius: 5px;
          margin-right: 7px;
        }
      }
    }
  }
}
</style>
