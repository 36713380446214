<template>
  <div class="we-data-field" @click="$emit('click')">
    <div class="icon-and-data" :class="{ 'no-icon': !icon }">
      <div class="icon" v-if="icon">
        <font-awesome-icon :icon="icon" />
      </div>
      <div class="data">
        <we-text-tooltip
          :text="label"
          maxWidth="fit-content"
          class="data-label"
        />
        <we-text-tooltip
          :text="value"
          maxWidth="fit-content"
          class="data-value"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    icon: String
  }
};
</script>

<style lang="scss">
.we-data-field {
  &.edit {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 7px;

    &:hover {
      background: var(--background-1-hover);
    }

    .icon-and-data {
      grid-template-columns: 16px 1fr;
    }
  }

  &.dashboard {
    .icon-and-data {
      .data {
        .data-value {
          font-size: 32px;
          font-weight: $semibold;
        }
      }
    }
  }

  &.error--text {
    .icon-and-data {
      .data {
        .data-value {
          color: var(--red);
        }
      }
    }
  }

  .icon-and-data {
    display: grid;
    grid-template-columns: 18px minmax(0, 1fr);
    gap: 22px;
    align-items: center;
    min-height: 46px;

    &.no-icon {
      display: block;
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    .data {
      min-width: 0;
      .data-label {
        font-size: 13px;
        color: var(--text-2);
        margin-bottom: 4px;
      }

      .data-value {
        font-size: 14px;
        font-weight: $medium;
        color: var(--text-1);
      }
    }
  }
}
</style>
