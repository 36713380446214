import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Default from "@/components/layouts/Default.vue";
import Home from "@/views/Index.vue";
import NotFound from "@/views/NotFound/Index.vue";

const routes = [
  {
    path: "/",
    component: Default,
    children: [
      {
        path: "/:domain/:formName",
        name: "domain",
        component: Home
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "NotFound") {
    next();
    return;
  }

  if (to?.params?.domain && to?.params?.formName) {
    next();
    return;
  }

  next({ name: "NotFound" });
});

export default router;
