import Vue from "vue";
import Vuetify, { VApp, VTextField } from "vuetify/lib";

export default new Vuetify({
  components: {
    VApp,
    VTextField
  },
  icons: {
    iconfont: "faSvg"
  },
  theme: {
    themes: {
      light: {
        primary: "#0C80F2",
        secondary: "#dcdfea",
        grey: "#71718C",
        accent: "#9AB0FB",
        error: "#FF4757",
        red: "#FF4757",
        info: "#1A1E2C",
        success: "#25b660",
        green: "#25b660",
        warning: "#FFCF03",
        indigo: "0060B8",
        orange: "FF704A",
        blue: "549CFC"
      }
    }
  }
});

Vue.use(Vuetify);
