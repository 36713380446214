<i18n>
{
  "en": {
    "title": "Form not found",
    "description": "The form you are trying to access no longer exists or has a configuration error.",
    "developed": "Developed by"
  },
  "pt-BR": {
    "title": "Formulário não encontrado",
    "description": "O formulário que está tentando acessar não existe mais ou está com erro de configuração.",
    "developed": "Desenvolvido por"
  }
}
</i18n>

<template>
  <div id="not-found">
    <div class="container">
      <img
        class="form-picture"
        src="@/assets/undraw/not-found.svg"
        :alt="$t('title')"
        width="325"
        height="222"
      />
      <h1 class="we-title">
        {{ $t("title") }}
      </h1>
      <p class="we-description">
        {{ $t("description") }}
      </p>
      <div class="by">
        {{ $t("developed") }}
        <a :href="link" target="_blank"> wepipe forms </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    domain() {
      return this.$store.getters.getDomain || "default";
    },
    link() {
      return `https://wepipe.com.br/?utm_source=app&utm_medium=form&utm_campaign=wepipe-organic&term=${this.domain}&utm_content=submitted-link`;
    }
  }
};
</script>

<style lang="scss">
#not-found {
  padding-top: 138px;
  padding-bottom: 120px;
  color: var(--text-1);
  text-align: center;
  .container {
    max-width: 713px;
    margin: 0 auto;
  }

  .form-picture {
    display: block;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
  }

  .we-title {
    margin: 0 auto;
    margin-bottom: 28px;
    font-size: 48px;
    line-height: 54px;
  }

  .we-description {
    margin: 0 auto;
  }

  .by {
    color: var(--text-2);
    font-size: 14px;
    margin-top: 32px;
  }
}
</style>
