<template>
  <div id="we-table-2">
    <div
      class="table-header"
      :style="{
        gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))`
      }"
    >
      <div class="header" v-for="header in headers" :key="header">
        {{ header }}
      </div>
    </div>
    <div
      class="table-data"
      :style="{
        gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))`
      }"
      v-for="(row, i) in data"
      :key="i"
    >
      <div
        class="data"
        v-for="(key, j) in Object.keys(row)"
        :key="j"
        :class="{ bold: row[key].bold }"
      >
        {{ getData(row[key]) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array
  },
  computed: {
    headers() {
      if (this.data.length <= 0) return [];

      return Object.keys(this.data[0]);
    }
  },
  methods: {
    getData(obj) {
      if (obj?.data?.length <= 0) return "";

      if (obj.type === "currency") {
        return this.$options.filters.valueToReais(obj.data);
      }

      return obj.data;
    }
  }
};
</script>

<style lang="scss">
#we-table-2 {
  border: 1px solid var(--line);

  .table-header {
    background: var(--background-1-hover);
    display: grid;

    .header {
      padding: 8px;
      font-size: 14px;
      border-right: 1px solid var(--line);
      text-transform: capitalize;
      &:last-child {
        border: none;
      }
    }
  }

  .table-data {
    background: var(--background-1);
    display: grid;
    border-top: 1px solid var(--line);

    .data {
      padding: 8px;
      font-size: 14px;
      border-right: 1px solid var(--line);

      &.bold {
        font-weight: $semibold;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
