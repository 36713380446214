<i18n>
{
  "en": {
    "title": "Select items",
    "description": "Please fill your order id to continue.",
    "item-return": "Return this item",
    "item-selected": "Item selected!",
    "item-disabled": "Não é possível devolver esse produto, favor entrar em contato com a central de atendimento",
    "return-reason": "Return reason",
    "total": "Total",
    "next": "Next step",
    "WRONG_SELECTED": "I chose the wrong product", 
    "WRONG_SENT": "Wrong product sent", 
    "NOT_NEEDED": "I no longer need the product", 
    "NOT_DIFFERENCE": "I didn't feel any difference when using the product",
    "REGRET": "I regret buying it", 
    "DAMAGED": "The product is damaged or defective", 
    "UNWANTED_REACTION": "The product caused me an unwanted reaction",
    "DELAYED": "The product took longer than expected", 
    "NOT_MATCH": "The product does not match the description",
    "OTHER": "Other",
    "next-error": "Wasn't possible to continue!",
    "next-error-description": "Check if you selected at least one item and fill the return reason."
  },
  "pt-BR": {
    "title": "Selecione os itens",
    "description": "Por favor, anexe uma foto de cada produto que deseja trocar e selecione-os abaixo.",
    "item-return": "Retornar esse item",
    "item-selected": "Item selecionado!",
    "item-disabled": "Não é possível devolver esse produto, favor entrar em contato com a central de atendimento",
    "return-reason": "Motivo de devolução",
    "total": "Total",
    "next": "Próximo",
    "WRONG_SELECTED": "Escolhi o produto errado", 
    "WRONG_SENT": "Enviaram o produto errado", 
    "NOT_NEEDED": "Não preciso mais do produto", 
    "NOT_DIFFERENCE": "Não senti diferença ao usar o produto",
    "REGRET": "Me arrependi de ter comprado", 
    "DAMAGED": "O produto está danificado ou com defeito", 
    "UNWANTED_REACTION": "O produto me causou uma reação indesejada",
    "DELAYED": "O produto demorou mais que o tempo previsto", 
    "NOT_MATCH": "O produto não está de acordo com a descrição",
    "OTHER": "Outro",
    "next-error": "Não foi possível continuar!",
    "next-error-description": "Verifique se selecionou pelo menos um item e colocou o motivo de retorno."
  }
}
</i18n>

<template>
  <section id="items-step">
    <div class="return-and-title">
      <we-icon-button icon="arrow-left" name="Voltar" @click="$emit('prev')" />
      <div class="we-title">{{ $t("title") }}</div>
    </div>
    <div class="we-description">{{ $t("description") }}</div>
    <we-drop-file ref="dropFileComponent" />
    <v-form ref="form" class="items">
      <div
        class="item"
        :class="{ active: item.active, disabled: item.disabled }"
        v-for="item in items"
        :key="item.id"
      >
        <v-switch
          v-if="!item.disabled"
          v-model="item.active"
          :label="item.active ? $t('item-selected') : $t('item-return')"
          hide-details
        />
        <div v-else class="disabled-message">
          <font-awesome-icon icon="circle-exclamation" />
          <span class="text">
            {{ $t("item-disabled") }}
          </span>
        </div>
        <div class="image-and-info">
          <picture class="image">
            <img :src="item.picture" :alt="item.name" />
          </picture>
          <div class="item-info">
            <div class="item-title">
              {{ item.name }}
            </div>
            <div class="price-and-quantity">
              <div class="price">
                {{ item.price | valueToReais }}
              </div>
              <div class="quantity-selector" v-if="item.active">
                <div class="quantity-button" @click="decreaseQuantity(item)">
                  <font-awesome-icon icon="minus" />
                </div>
                <input
                  v-model="item.quantity"
                  type="text"
                  v-maska="'##'"
                  @blur="checkQuantity($event, item)"
                />
                <div class="quantity-button" @click="increaseQuantity(item)">
                  <font-awesome-icon icon="plus" />
                </div>
              </div>
            </div>
            <we-input
              v-if="item.active"
              v-model="item.return_reason"
              type="global-select"
              is-required
              :items="returnReasons"
              :placeholder="$t('return-reason')"
            />
          </div>
        </div>
      </div>
    </v-form>
    <div class="total-and-next">
      <div class="total">
        {{ $t("total") }}:
        <span class="amount">{{ total | valueToReais }}</span>
      </div>
      <we-button
        class="primary"
        :text="$t('next')"
        icon="arrow-right"
        icon-position="right"
        @click="next"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: []
    };
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    },
    returnReasons() {
      return this.returns.return_reasons.map(e => {
        return {
          text: this.$t(e),
          value: e
        };
      });
    },
    order() {
      return this.$store.getters.getOrder;
    },
    total() {
      const initial = 0;

      const total = this.items.reduce((prev, current) => {
        if (!current.active) return prev;

        return prev + current.price * current.quantity;
      }, initial);

      return total;
    },
    selectedItems() {
      return this.items.filter(e => e.active === true && e.return_reason);
    }
  },
  methods: {
    next() {
      const validated = this.$refs.form.validate();

      if (validated) {
        if (this.selectedItems.length <= 0) {
          const snackBar = {
            title: this.$t("next-error"),
            description: this.$t("next-error-description"),
            status: "error"
          };

          this.$store.commit("setSnackBar", snackBar);
          this.$store.commit("addSnackBar");
          return;
        }

        this.$emit("next", this.selectedItems);
      }
    },
    increaseQuantity(item) {
      if (item.quantity < item.max_quantity) {
        item.quantity++;
      }
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    checkQuantity(e, item) {
      if (Number(e.target.value) <= 0) {
        item.quantity = 1;
      }
      if (Number(e.target.value) > item.max_quantity) {
        item.quantity = item.max_quantity;
      }
    }
  },
  mounted() {
    this.items = this.order.map(e => {
      return {
        id: e.wepipe_product.id,
        sku: e.order_product.sku,
        name: e.order_product.name,
        price: e.order_product.price,
        quantity: e.order_product.quantity,
        max_quantity: e.order_product.quantity,
        picture: e.wepipe_product.picture,
        active: false,
        disabled: e.order_product.sku !== e.wepipe_product.sku,
        return_reason: null
      };
    });
  }
};
</script>

<style lang="scss">
section#items-step {
  .we-description {
    margin-bottom: 24px;
  }
  .we-drop-file {
    margin-bottom: 24px;
  }
  .items {
    .item {
      margin-bottom: 16px;
      padding: 16px;
      border: 1px solid var(--line);
      border-radius: 10px;

      &.active {
        border-color: var(--primary);
      }

      &.disabled {
        background: var(--background-2);

        .image-and-info {
          .item-info {
            .item-title {
              color: var(--text-3);
            }
          }
        }
      }

      .v-input--switch {
        margin-bottom: 16px;
      }

      .disabled-message {
        display: flex;
        align-items: center;
        color: var(--red);
        margin-bottom: 16px;
        svg {
          height: 18px;
          width: 18px;
          margin-right: 8px;
        }

        .text {
          font-size: 14px;
        }
      }
      .image-and-info {
        display: grid;
        grid-template-columns: 74px minmax(0, 1fr);
        gap: 24px;
        .image {
          display: block;
          width: 100%;
          height: 74px;
          border-radius: 8px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
          }
        }
        .item-info {
          padding-top: 8px;
          .item-title {
            font-size: 14px;
            font-weight: $medium;
            color: var(--text-1);
            margin-bottom: 4px;
          }
          .price-and-quantity {
            height: 36px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .price {
              font-size: 14px;
              color: var(--green);
              font-weight: $semibold;
            }
            .quantity-selector {
              display: flex;
              align-items: center;
              pointer-events: all;
              border: 1px solid var(--line);
              border-radius: 18px;
              height: 36px;
              overflow: hidden;
              margin-left: 16px;

              &:hover {
                border-color: var(--text-2);
              }

              .quantity-button {
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                cursor: pointer;

                &:hover {
                  background: var(--primary-bg);

                  svg {
                    color: var(--primary);
                  }
                }

                svg {
                  width: 11px;
                  height: auto;
                  color: var(--text-2);
                }
              }

              input {
                border: none;
                background: var(--inherit);
                font-size: 16px;
                line-height: 16px;
                font-weight: $semibold;
                color: var(--text-2);
                width: 31px;
                text-align: center;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                &:focus {
                  border: none;
                  outline: none !important;
                  box-shadow: none !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .total-and-next {
    display: flex;
    align-items: center;
    margin-top: 32px;
    .total {
      font-size: 20px;
      flex: 1;

      .amount {
        font-weight: $semibold;
        color: var(--green);
      }
    }

    .we-button {
      width: 100%;
      max-width: 160px;
    }
  }
}
</style>
