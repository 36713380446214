<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!name || !isDesktop"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        class="we-icon-button icon"
        @click.prevent="$emit('click')"
        v-bind="attrs"
        v-on="on"
        :class="[color, { active }, componentClass, type, size]"
        :bg="bg"
        :iconSize="iconSize"
        :cy="cy"
      >
        <span class="amount" v-if="amount > 0">{{ amount }}</span>
        <font-awesome-icon v-if="icon" :icon="icon" />
        <span class="text" v-else> {{ text }} </span>
      </button>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "we-icon-button",
  props: {
    icon: [String, Object, Array],
    iconSize: String,
    direction: {
      type: String,
      default: "top"
    },
    name: String,
    color: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "circle"
    },
    size: {
      type: String,
      default: "medium"
    },
    amount: {
      type: Number,
      default: 0
    },
    text: String,
    active: Boolean,
    bg: String,
    cy: String
  },
  computed: {
    componentClass() {
      if (this.$options._parentVnode.data.staticClass) {
        return this.$options._parentVnode.data.staticClass;
      }

      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.we-icon-button {
  min-width: 38px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: var(--text-2);

  &.big-mobile {
    @include mobile {
      height: 50px;
      width: 50px;
      border-radius: 50px;
    }
  }

  &:hover {
    background: rgba(var(--text-2-rgb), 0.1);
  }

  &.active {
    background: var(--primary-bg);
    svg,
    span {
      color: var(--primary);
    }
  }

  &.square {
    border-radius: 3px;
  }

  &.small {
    min-width: 32px;
    width: 32px;
    height: 32px;

    svg {
      height: 16px;
    }

    span {
      font-size: 14px;
    }
  }

  &[iconSize="small"] {
    svg {
      height: 12px;
    }
  }

  &.icon-primary {
    svg {
      color: var(--primary);
    }
  }

  &.icon-white {
    svg {
      color: var(--white);
    }
  }

  &.icon-red {
    svg {
      color: var(--red);
    }
  }

  &.icon-green {
    svg {
      color: var(--green);
    }
  }

  &.icon-orange {
    svg {
      color: var(--orange);
    }
  }

  .amount {
    height: 13px;
    padding: 0 4px;
    background: var(--orange);
    font-size: 10px;
    font-weight: $bold;
    color: var(--white);
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
    min-width: 16px;
    justify-content: center;
  }

  .text {
    font-size: 18px;
    color: var(--text-2);
    font-weight: $bold;
    letter-spacing: 1px;
  }

  svg {
    pointer-events: none;
    height: 18px;
    width: auto;
  }
}
</style>
