<i18n>
{
  "en": {
    "title": "Wait, we have a special offer to you!",
    "redeem-1": "Redeem a store credit with",
    "redeem-2": "more",
    "redeem-3": "than the amount you paid",
    "redeem": "Redeem",
    "redeem-obs": "* If you redeem the store credit you won't be refunded in your bank account",
    "close-message": "Seconds to redeem the store credit"
  },
  "pt-BR": {
    "title": "Espera, temos uma oferta especial para você!",
    "redeem-1": "Resgate crédito na loja com",
    "redeem-2": "a mais",
    "redeem-3": "que o valor dos produtos",
    "redeem": "Resgatar",
    "redeem-obs": "* Se você resgatar o crédito na loja, não receberá o dinheiro na conta bancária",
    "close-message": "Segundos para resgatar o crédito na loja"
  }
}
</i18n>

<template>
  <modal-action class="special-offer" @close="close">
    <template #view>
      <div class="view">
        <div class="modal">
          <we-icon-button
            class="big-mobile close"
            icon="times"
            @click="close"
          />
          <div class="we-title">
            {{ $t("title") }}
          </div>
          <div class="card-redeem">
            <img
              width="188"
              height="145"
              src="@/assets/gift.svg"
              :alt="$t('title')"
            />
            <div class="card-content">
              <div class="text">
                {{ $t("redeem-1") }}
                <span class="green--text"
                  >{{ returns.special_offer_percentage }}%
                  {{ $t("redeem-2") }}</span
                >
                {{ $t("redeem-3") }}
              </div>
              <we-button
                @click="redeem"
                icon="sack-dollar"
                class="success"
                :text="
                  `${$t('redeem')} ${$options.filters.valueToReais(
                    itemsPriceWithPercentage
                  )}`
                "
              />
            </div>
          </div>
          <div class="obs-redeem">{{ $t("redeem-obs") }}</div>
          <div class="close-seconds">
            {{ seconds }}
          </div>
          <div class="close-message">{{ $t("close-message") }}</div>
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      cepLoading: false,
      seconds: 60
    };
  },
  methods: {
    ...mapActions(["cepRequest"]),
    close() {
      this.$emit("close");
    },
    redeem() {
      this.$emit("redeem");
      this.clearSpecialOffer();
    },
    clearSpecialOffer() {
      this.close();
    }
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    },
    selectedItems() {
      return this.$store.getters.getSelectedItems;
    },
    itemsPrice() {
      let price = 0;
      this.selectedItems.forEach(e => {
        price += e.price;
      });
      return price;
    },
    itemsPriceWithPercentage() {
      return parseFloat(
        (this.itemsPrice * (100 + this.returns.special_offer_percentage)) / 100
      ).toFixed(2);
    }
  },
  mounted() {
    const timer = setInterval(() => {
      this.seconds--;

      if (this.seconds === 0) {
        this.clearSpecialOffer();
        clearInterval(timer);
      }
    }, 1000);
  }
};
</script>

<style lang="scss">
#action_modal.special-offer {
  .view {
    .modal {
      width: 100%;
      max-width: 658px;
      padding: 32px;

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .we-title {
        font-size: 24px;
        font-weight: $semibold;
        margin-bottom: 40px;
        text-align: center;
      }

      .card-redeem {
        display: grid;
        grid-template-columns: 188px 1fr;
        align-items: center;
        gap: 32px;
        padding: 10px 10px 0 10px;
        border-radius: 8px;
        border: 1px solid var(--line);
        margin-bottom: 32px;

        img {
          width: 100%;
          max-width: 188px;
          height: auto;
        }

        .card-content {
          padding-bottom: 16px;
          .text {
            font-size: 20px;
            font-weight: $semibold;
            margin-bottom: 12px;
          }
        }
      }

      .obs-redeem {
        max-width: 392px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        color: var(--text-2);
        margin-bottom: 48px;
      }

      .close-seconds {
        font-size: 96px;
        line-height: 96px;
        font-weight: $bold;
        color: var(--red);
        margin-bottom: 8px;
        text-align: center;
      }

      .close-message {
        max-width: 392px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        color: var(--text-2);
      }
    }
  }
}
</style>
