<template>
  <div class="we-snack-bar-card" :class="{ borrow }">
    <div class="status-icon" v-if="item.status === 'success'">
      <font-awesome-icon icon="check" />
    </div>
    <div class="status-icon error" v-if="item.status === 'error'">
      <font-awesome-icon icon="times" />
    </div>
    <div class="status-icon warning" v-if="item.status === 'warning'">
      <font-awesome-icon icon="exclamation" />
    </div>
    <div
      class="we-title"
      v-html="item.title"
      :class="{ mb: item.description }"
    ></div>
    <template v-if="item.description">
      <div v-if="!Array.isArray(item.description)" class="description">
        {{ item.description }}
      </div>
      <ul v-else class="description">
        <li v-for="(error, i) in item.description" :key="i">
          {{ error }}
        </li>
      </ul>
    </template>
    <we-icon-button class="close" icon="times" @click="borrow = true" />
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    index: Number
  },
  data() {
    return {
      progress: 0,
      interval: "",
      borrow: false
    };
  },
  methods: {
    remove() {
      this.$store.commit("removeSnackBar", this.index);
    }
  },
  mounted() {
    setTimeout(() => {
      this.remove();
    }, this.item.time);
  }
};
</script>

<style lang="scss">
.we-snack-bar-card {
  width: 100%;
  padding: 20px 56px;
  position: relative;
  margin-bottom: 12px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  background: var(--background-1);
  border-radius: 10px;
  pointer-events: auto;
  overflow: hidden;

  &.borrow {
    display: none;
  }

  .status-icon {
    width: 22px;
    height: 22px;
    min-height: 22px;
    border-radius: 22px;
    background: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 18px;
    height: 18px;
    padding: 4px;

    @include mobile {
      width: 18px;
      height: 18px;
      min-height: 18px;
      padding: 2px;
    }

    &.error {
      background: var(--red);
    }

    svg {
      color: var(--background-1);
      height: 100%;
      width: auto;
    }
  }

  .we-title {
    font-weight: $medium;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-1);

    @include mobile {
      font-size: 14px;
      line-height: 16px;
    }

    &.mb {
      margin-bottom: 12px;
    }

    b {
      font-weight: $semibold;
      color: var(--text-1);
    }
  }

  .description {
    font-weight: $regular;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-2);
    list-style: initial;

    @include mobile {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .we-icon-button.close {
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
</style>
