var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":_vm.direction === 'top',"right":_vm.direction === 'right',"bottom":_vm.direction === 'bottom',"left":_vm.direction === 'left',"disabled":!_vm.name || !_vm.isDesktop,"open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"rounded-picture",class:[_vm.type, { disabled: _vm.disabled }],style:({
        height: _vm.size + 'px',
        minHeight: _vm.size + 'px',
        width: _vm.size + 'px',
        minWidth: _vm.size + 'px'
      })},'div',attrs,false),on),[(_vm.picture)?_c('img',{attrs:{"src":_vm.picture,"alt":_vm.name,"width":_vm.size,"height":_vm.size}}):(_vm.type === 'product')?_c('img',{attrs:{"src":require("@/assets/product.svg"),"alt":_vm.name,"width":_vm.size,"height":_vm.size}}):(_vm.type === 'company')?_c('img',{attrs:{"src":require("@/assets/company.svg"),"alt":_vm.name,"width":_vm.size,"height":_vm.size}}):_c('img',{attrs:{"src":require("@/assets/profile.svg"),"alt":_vm.name,"width":_vm.size,"height":_vm.size}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.name))])])}
var staticRenderFns = []

export { render, staticRenderFns }