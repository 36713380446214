import i18n from "@/plugins/i18n";
import Vue from "vue";

const messages = {
  en: {
    "cep-error": "Wasn't possible check the CEP!"
  },
  "pt-BR": {
    "cep-error": "Não foi possível verificar o CEP!"
  }
};

i18n._vm.messages = Vue.prototype.$deepMerge(i18n._vm.messages, messages);

export default {
  state: {
    cep: {}
  },
  getters: {
    getCep: state => state.cep
  },
  mutations: {
    setCep: (state, payload) => {
      state.cep = payload;
    }
  },
  actions: {
    cepRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$httpCep
          .get(`/ws/${payload}/json/`)
          .then(resp => {
            const cep = resp;

            if (resp.erro) {
              const snackBar = {
                title: i18n.t("cep-error")
              };

              commit("setSnackBar", snackBar);
              commit("addSnackBar");
              resolve(resp);
            }

            commit("setCep", cep);

            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: i18n.t("cep-error")
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
