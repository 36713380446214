export default {
  state: {
    snackBarEnabled: true,
    snackBars: [],
    snackBar: {
      title: "",
      description: "",
      status: "",
      time: "",
      show: false
    }
  },
  getters: {
    getSnackBars: state => state.snackBars
  },
  mutations: {
    setSnackBar: (state, payload) => {
      state.snackBar = { ...state.snackBar, ...payload };
    },
    addSnackBar: (state, payload) => {
      if (state.snackBarEnabled) {
        if (!payload) {
          payload = state.snackBar;
        }

        const hash = Math.random()
          .toString(36)
          .substring(7);
        payload["hash"] = hash;
        if (!("time" in payload) || payload?.time?.length <= 0) {
          payload["time"] = 5000;
        }

        state.snackBars = [...state.snackBars, payload];
      }
    },
    removeSnackBar: (state, payload) => {
      state.snackBars.splice(payload, 1);
    },
    ableSnackBar: (state, payload) => {
      state.snackBarEnabled = payload;
    }
  }
};
