<template>
  <div
    class="we-card-icon"
    :color="color"
    :title="title"
    @click="$emit('click')"
  >
    <div class="icon-and-content">
      <div class="item-icon">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="darken"
          size="16"
          width="3"
        ></v-progress-circular>
        <font-awesome-icon v-else-if="icon" :icon="icon" />
      </div>
      <div class="item-content">
        <div class="item-label">
          {{ label }}
        </div>
        <div class="item-title">
          {{ title }}
        </div>
        <div v-if="$slots['sublabel']" class="item-sublabel">
          <slot name="sublabel" />
        </div>
      </div>
    </div>
    <div v-if="$slots['obs']" class="item-obs">
      <slot name="obs" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    icon: String,
    label: String,
    title: String,
    loading: Boolean
  }
};
</script>

<style lang="scss">
.we-card-icon {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--line);
  position: relative;
  display: flex;
  align-items: center;

  &.button {
    cursor: pointer;
    &:hover {
      border-color: var(--primary);
      background: var(--background-2);
    }
  }

  &.active {
    border-color: var(--primary);

    .item-icon {
      background: var(--primary-bg);

      svg {
        color: var(--primary);
      }
    }
  }

  &[color="primary"] {
    .item-icon {
      background: var(--primary-bg);

      svg {
        color: var(--primary);
      }
    }
  }

  &[color="blue"] {
    .item-icon {
      background: rgba(var(--blue-rgb), 0.1);

      svg {
        color: var(--blue);
      }
    }
  }

  &[color="green"] {
    .item-icon {
      background: rgba(var(--green-rgb), 0.1);

      svg {
        color: var(--green);
      }
    }
  }

  &[color="lightBlue"] {
    .item-icon {
      background: rgba(var(--lightBlue-rgb), 0.1);

      svg {
        color: var(--lightBlue);
      }
    }
  }

  &[color="red"] {
    .item-icon {
      background: rgba(var(--red-rgb), 0.1);

      svg {
        color: var(--red);
      }
    }
  }

  &[color="black"] {
    .item-icon {
      background: rgba(var(--black-rgb), 0.1);

      svg {
        color: var(--black);
      }
    }
  }

  &[color="yellow"] {
    .item-icon {
      background: rgba(var(--yellow-rgb), 0.1);

      svg {
        color: var(--yellow);
      }
    }
  }

  &[color="orange"] {
    .item-icon {
      background: rgba(var(--orange-rgb), 0.1);

      svg {
        color: var(--orange);
      }
    }
  }

  &[color="purple"] {
    .item-icon {
      background: rgba(var(--purple-rgb), 0.1);

      svg {
        color: var(--purple);
      }
    }
  }

  .icon-and-content {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr;
    gap: 15px;
    flex: 1;
  }

  .item-icon {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: var(--line-background);
    padding: 13px;

    .v-progress-circular {
      height: 19px;
      width: 19px;
    }

    svg {
      color: var(--text-2);
      height: 19px;
    }
  }

  .item-content {
    height: fit-content;
    .item-label {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 4px;
      text-transform: uppercase;
      font-weight: $medium;
      color: var(--text-3);
      letter-spacing: 1px;
    }
    .item-title {
      font-size: 13px;
      line-height: 16px;
      font-weight: $semibold;
      color: var(--text-1);
    }
    .item-sublabel {
      margin-top: 2px;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      font-weight: $medium;
      color: var(--text-2);

      svg {
        height: 10px;
        margin-right: 4px;
      }
    }
  }

  .item-obs {
    font-size: 16px;
  }
}
</style>
