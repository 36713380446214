export default {
  state: {
    queryParams: {}
  },
  getters: {
    getQueryParams: state => state.queryParams,
    getUtms: state => {
      const utms = {};

      Object.keys(state.queryParams).forEach(key => {
        if (key.includes("utm")) {
          utms[key] = state.queryParams[key];
        }
      });

      return utms;
    }
  },
  mutations: {
    setQueryParams: (state, payload) => {
      state.queryParams = payload;
    }
  }
};
