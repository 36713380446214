<i18n>
{
  "en": {
    "title": "Congratulations, your return request is done! 🎉",
    "description": "Save your return request number, check your email and follow the instructions to finish the return.",
    "request-number": "Request number",
    "copied": "Copied to clipboard!",
    "email": "Email",
    "POSTOFFICE": "Send it back",
    "STORE": "Drop off in a store",
    "HOME_COLLECTION": "Home collection",
    "REFUND": "Refund",
    "STORE_CREDIT": "Store credit",
    "shipping": "Shipping method",
    "chargeback": "Chargeback method"
  },
  "pt-BR": {
    "title": "Parabéns, agora você pode enviar os produtos de volta! 🎉",
    "description": "Salve o seu número de solicitação e acompanhe as instruções no seu e-mail para finalizar a devolução.",
    "request-number": "Número da solicitação",
    "copied": "Número copiado!",
    "email": "E-mail",
    "POSTOFFICE": "Postar pelo correios",
    "STORE": "Deixar na loja",
    "HOME_COLLECTION": "Coleta em casa",
    "REFUND": "Reembolso",
    "STORE_CREDIT": "Crédito na loja",
    "shipping": "Método de devolução",
    "chargeback": "Método de estorno"
  }
}
</i18n>

<template>
  <section id="completed">
    <div class="we-title">
      {{ $t("title") }}
    </div>
    <div class="we-description">
      {{ $t("description") }}
    </div>
    <v-tooltip top v-model="copied">
      <template v-slot:activator="{ on, attrs }">
        <we-data-field
          v-bind="attrs"
          v-on="on"
          class="edit"
          icon="fingerprint"
          :label="$t('request-number')"
          :value="returnRequest.code"
          @click="copyCodeToClipboard"
        />
      </template>
      <span>{{ $t("copied") }}</span>
    </v-tooltip>
    <we-data-field
      class="edit"
      icon="envelope"
      :label="$t('email')"
      :value="orderInputs.email"
    />
    <div class="completed-grid">
      <we-data-field
        class="edit"
        :icon="icons[selectedShipping]"
        :label="$t('shipping')"
        :value="$t(selectedShipping)"
      />
      <we-data-field
        class="edit"
        :icon="icons[selectedChargeback]"
        :label="$t('chargeback')"
        :value="$t(selectedChargeback)"
      />
    </div>
    <img class="undraw" src="@/assets/undraw/repack.svg" alt="Repack" />
  </section>
</template>

<script>
export default {
  data() {
    return {
      copied: false,
      icons: {
        POSTOFFICE: "truck",
        STORE: "store",
        HOME_COLLECTION: "people-carry-box",
        REFUND: "wallet",
        STORE_CREDIT: "gift"
      }
    };
  },
  computed: {
    selectedShipping() {
      return this.$store.getters.getSelectedShipping;
    },
    selectedChargeback() {
      return this.$store.getters.getSelectedChargeback;
    },
    returnRequest() {
      return this.$store.getters.getReturnRequest;
    },
    orderInputs() {
      return this.$store.getters.getOrderInputs;
    }
  },
  methods: {
    async copyCodeToClipboard() {
      try {
        await navigator.clipboard.writeText(this.returnRequest.code);
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss">
#returns {
  #completed {
    .we-description {
      margin-bottom: 16px;
    }

    .completed-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 8px;
      margin-top: 8px;
    }

    .undraw {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 280px;
      height: auto;
      margin-top: 32px;
    }
  }
}
</style>
