<i18n>
{
  "en": {
    "loading": "Loading"
  },
  "pt-BR": {
    "loading": "Carregando"
  }
}
</i18n>

<template>
  <div class="we-loading-overflow" v-if="loading">
    <div class="text">{{ $t("loading") }}</div>
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
};
</script>

<style lang="scss">
.we-loading-overflow {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(var(--background-1-rgb), 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  &.small {
    .text {
      font-size: 13px;
      margin-bottom: 4px;
    }

    .v-progress-linear {
      max-width: 80px;
    }
  }

  .text {
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 11px;
  }

  .v-progress-linear {
    max-width: 300px;
    border-radius: 4px;
  }
}
</style>
