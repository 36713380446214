<template>
  <v-tooltip
    :top="direction === 'top'"
    :right="direction === 'right'"
    :bottom="direction === 'bottom'"
    :left="direction === 'left'"
    :disabled="!text || !isDesktop || disabled"
    :open-delay="delay"
    content-class="we-text-tooltip-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="we-text-tooltip"
        :class="[
          componentClass,
          propsClass,
          { custom: lines != '1', disabled: disabled }
        ]"
        v-bind="attrs"
        v-on="on"
        :style="`max-width: ${maxWidth}; -webkit-line-clamp: ${lines};`"
        @click="$emit('click')"
      >
        {{ text }}
      </div>
    </template>
    <slot name="tooltip-content" />
    <span v-if="!$slots['tooltip-content']">
      {{ tooltipText ? tooltipText : text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "we-text-tooltip",
  props: {
    text: [String, Object, Number],
    tooltipText: [String, Object, Number],
    direction: {
      type: String,
      default: "top"
    },
    lines: {
      type: String,
      default: "1"
    },
    delay: {
      type: Number,
      default: 0
    },
    disabled: Boolean,
    maxWidth: String
  },
  computed: {
    componentClass() {
      if (this.$options._parentVnode.data.staticClass) {
        return this.$options._parentVnode.data.staticClass;
      }

      return "";
    },
    propsClass() {
      if (this.$options._parentVnode.data.class) {
        return this.$options._parentVnode.data.class;
      }

      return "";
    },
    wrap() {
      return this.lines != "1" ? "initial" : "nowrap";
    }
  }
};
</script>

<style lang="scss">
.we-text-tooltip {
  max-width: fit-content;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.custom {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
  }
}

.we-text-tooltip-content {
  .tooltip-products {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--line);
  }
  .tooltip-product {
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    gap: 6px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .product-data {
      display: flex;
      flex-flow: column;
      justify-content: center;

      .product-name {
        font-size: 13px;
        margin-bottom: 4px;
      }

      .product-price {
        font-size: 14px;
        color: var(--green);
        font-weight: $semibold;
      }
    }
  }
  .tooltip-values {
    .tooltip-value {
      margin-bottom: 4px;
      font-size: 13px;
      font-weight: $semibold;
      text-align: center;

      span {
        color: var(--green);
        font-size: 14px;
      }
    }
  }
}
</style>
