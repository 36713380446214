import Vue from "vue";

export default {
  state: {
    returns: {},
    routeParams: {},
    selectedItems: null,
    selectedShipping: null,
    selectedChargeback: null,
    returnRequest: null,
    sessionId: null
  },
  getters: {
    getReturns: state => state.returns,
    getRouteParams: state => state.routeParams,
    getSelectedItems: state => state.selectedItems,
    getSelectedShipping: state => state.selectedShipping,
    getSelectedChargeback: state => state.selectedChargeback,
    getReturnRequest: state => state.returnRequest,
    getSessionId: state => state.sessionId
  },
  mutations: {
    setReturns: (state, payload) => {
      state.returns = payload;
    },
    setRouteParams: (state, payload) => {
      state.routeParams = payload;
    },
    setSelectedItems: (state, payload) => {
      state.selectedItems = payload;
    },
    setSelectedShipping: (state, payload) => {
      state.selectedShipping = payload;
    },
    setSelectedChargeback: (state, payload) => {
      state.selectedChargeback = payload;
    },
    setReturnRequest: (state, payload) => {
      state.returnRequest = payload;
    },
    setSessionId: (state, payload) => {
      state.sessionId = payload;
    }
  },
  actions: {
    returnsRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/addons/returns/public/${payload.domain}/${payload.formName}`)
          .then(resp => {
            let returns = resp.data || null;

            if (
              returns.return_reasons === null ||
              returns.return_reasons.length <= 0
            ) {
              returns.return_reasons = [
                "WRONG_SELECTED",
                "WRONG_SENT",
                "NOT_NEEDED",
                "NOT_DIFFERENCE",
                "REGRET",
                "DAMAGED",
                "UNWANTED_REACTION",
                "DELAYED",
                "NOT_MATCH",
                "OTHER"
              ];
            }

            commit("setReturns", returns);
            resolve(resp);
          })
          .catch(err => {
            resolve(err);
          });
      });
    },
    createReturnsRequest({ commit, getters }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(
            `/addons/returns/public/${getters.getRouteParams.domain}/${getters.getRouteParams.formName}/requests`,
            payload
          )
          .then(resp => {
            let request = resp.data || null;

            commit("setReturnRequest", request);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: "Não foi possível solicitar a devolução!",
              description:
                "Favor entrar em contato com a central de atendimento."
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
