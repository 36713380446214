import Vue from "vue";
import axios from "axios";
import store from "@/store";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: process.env.VUE_APP_API,
      headers: {
        "Content-Type": "application/json"
      }
    });

    Vue.prototype.$httpCep = axios.create({
      baseURL: "https://viacep.com.br/",
      headers: {
        "Content-Type": "application/json"
      }
    });

    Vue.prototype.$http.interceptors.request.use(
      config => {
        let token = localStorage.getItem("we-form-token");

        if (!token) {
          token = sessionStorage.getItem("we-form-token");
        }

        if (token) {
          config.headers.Authorization = `${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    Vue.prototype.$http.interceptors.response.use(
      config => config,
      error => {
        if (error.response.status === 500) {
          const snackBar = {
            time: 5000,
            status: "error"
          };

          store.commit("setSnackBar", snackBar);
        }
        if (
          typeof error.response.data === "object" &&
          error.response.data !== null
        ) {
          const errors = error.response.data;

          const errorsArr = Object.keys(errors).map(e => {
            const value = errors[e][0];

            return value;
          });

          const snackBar = {
            description: errorsArr,
            time: 5000,
            status: "error"
          };

          store.commit("setSnackBar", snackBar);
        }

        return Promise.reject(error);
      }
    );
  }
});
