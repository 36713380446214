<i18n>
{
  "en": {
    "title": "Welcome to return page",
    "description": "Please fill your order id to continue.",
    "input-email-label": "Email",
    "input-email-placeholder": "Please fill your email",
    "input-order-label": "Order",
    "input-order-placeholder": "Order number",
    "next": "Next step",
    "or": "Or",
    "need-help": "Need help?",
    "contact": "Contact our team"
  },
  "pt-BR": {
    "title": "Bem-vindo a página de devolução",
    "description": "Para começar, preencha os dados do pedido abaixo.",
    "input-email-label": "Email",
    "input-email-placeholder": "Email",
    "input-order-label": "Número do pedido",
    "input-order-placeholder": "Número do pedido",
    "next": "Próximo",
    "or": "Ou",
    "need-help": "Precisa de ajuda?",
    "contact": "Converse com nossos atendentes"
  }
}
</i18n>

<template>
  <section id="order-step">
    <v-form ref="form" @submit.prevent>
      <div class="we-title">
        {{ $t("title") }}
      </div>
      <div class="we-description">{{ $t("description") }}</div>
      <we-input
        type="email"
        v-model="payload.email"
        :label="$t('input-email-label')"
        :placeholder="$t('input-email-label')"
        is-required
      />
      <div class="order-and-next">
        <we-input
          type="text"
          prefix="#"
          mask="##########"
          v-model="payload.order"
          :label="$t('input-order-label')"
          placeholder="1234"
          is-required
        />
        <we-button
          class="primary"
          :text="$t('next')"
          icon="arrow-right"
          icon-position="right"
          :loading="loading"
          @click="next"
        />
      </div>
    </v-form>
    <template v-if="returns.help_center_url">
      <div class="or">
        <div class="text">{{ $t("or") }}</div>
      </div>
      <we-card-icon
        class="button"
        :label="$t('need-help')"
        :title="$t('contact')"
        icon="headset"
        @click="openHelpCenter"
      />
    </template>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      payload: {
        email: "",
        order: ""
      }
    };
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    },
    routeParams() {
      return this.$store.getters.getRouteParams;
    },
    queryParams() {
      return this.$store.getters.getQueryParams;
    }
  },
  methods: {
    ...mapActions(["orderRequest"]),
    async next() {
      this.loading = true;
      const validated = this.$refs.form.validate();

      if (validated) {
        const order = await this.orderRequest({
          customer_domain: this.routeParams.domain,
          form_domain: this.routeParams.formName,
          order_id: this.payload.order
        });

        if (order.status === 200) {
          this.$emit("next", this.payload);
        }
      }
      this.loading = false;
    },
    openHelpCenter() {
      window.open(this.returns.help_center_url, "_blank");
    }
  },
  mounted() {
    if (this.queryParams?.order) {
      this.payload.order = this.queryParams?.order;
    }

    if (this.queryParams?.email) {
      this.payload.email = this.queryParams?.email;
    }
  }
};
</script>

<style lang="scss">
#order-step {
  .we-input {
    margin-bottom: 16px;
  }
  .order-and-next {
    display: grid;
    grid-template-columns: 1fr 160px;
    gap: 16px;

    @include sm {
      grid-template-columns: 1fr;
    }

    .we-input {
      margin: 0;
    }

    .we-button {
      margin-top: 15px;
    }
  }
  .or {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $semibold;
    color: var(--text-3);
    position: relative;
    margin: 48px 0;
    justify-content: center;

    &::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 7px;
      background: var(--line);
    }

    .text {
      padding: 0 32px;
      background: var(--background-1);
      position: relative;
      z-index: 1;
    }
  }
}
</style>
