<template>
  <div class="we-snack-bar">
    <div class="list">
      <transition-group name="list">
        <we-snack-bar-card
          v-for="(item, i) in snackBars"
          :item="item"
          :index="i"
          :key="item.hash"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import WeSnackBarCard from "./WeSnackBarCard.vue";

export default {
  components: {
    WeSnackBarCard
  },
  computed: {
    snackBars() {
      return this.$store.getters.getSnackBars;
    }
  }
};
</script>

<style lang="scss">
.list-enter-active {
  transition: all 0.5s;
}
.list-enter /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-move {
  transition: transform 0.5s;
}

.we-snack-bar {
  position: fixed;
  height: 100vh;
  width: 100vh;
  pointer-events: none;
  z-index: 99999999;

  .list {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 394px;
    left: 35px;
    bottom: 0;
    display: flex;
    flex-flow: column-reverse;
    pointer-events: none;

    @include mobile {
      max-width: calc(100vw - 30px);
      left: 15px;
    }
  }
}
</style>
