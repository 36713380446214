<i18n>
{
  "en": {
    "title": "Shipping method",
    "address": "Address",
    "method": "Method",
    "next": "Next step",
    "no-fee": "No fee",
    "fee": "fee",
    "POSTOFFICE": "Send it back",
    "STORE": "Drop off in a store",
    "HOME_COLLECTION": "Home collection"
  },
  "pt-BR": {
    "title": "Método de devolução",
    "address": "Endereço",
    "method": "Método de devolução",
    "next": "Próximo",
    "no-fee": "Sem taxas",
    "fee": "de taxa",
    "POSTOFFICE": "Postar pelo correios",
    "STORE": "Deixar na loja",
    "HOME_COLLECTION": "Coleta em casa"
  }
}
</i18n>

<template>
  <section id="shipping-step">
    <return-address v-if="addressOpened" @close="addressOpened = false" />
    <div class="return-and-title">
      <we-icon-button icon="arrow-left" name="Voltar" @click="$emit('prev')" />
      <div class="we-title">
        {{ $t("title") }}
      </div>
    </div>
    <!-- <we-data-field
      @click="addressOpened = true"
      class="edit"
      icon="globe-americas"
      :label="$t('address')"
      :value="
        `${address.street}, ${address.number}, ${address.note} - ${address.zipcode}`
      "
    /> -->
    <div class="options">
      <div
        v-if="returns.is_postoffice_return_enabled"
        class="option"
        :class="{ active: shippingSelected === 'POSTOFFICE' }"
        @click="shippingSelected = 'POSTOFFICE'"
      >
        <we-card-icon
          class="button"
          :class="{ active: shippingSelected === 'POSTOFFICE' }"
          icon="truck"
          :label="$t('method')"
          :title="$t('POSTOFFICE')"
        >
          <!-- <template #obs>
            <span :class="{ fee: method.fee > 0 }">{{ fee(method) }}</span>
          </template> -->
        </we-card-icon>
        <div
          v-if="returns.postoffice_return_details"
          class="option-description"
          v-html="returns.postoffice_return_details"
        ></div>
      </div>
      <div
        v-if="returns.is_store_return_enabled"
        class="option"
        :class="{ active: shippingSelected === 'STORE' }"
        @click="shippingSelected = 'STORE'"
      >
        <we-card-icon
          class="button"
          :class="{ active: shippingSelected === 'STORE' }"
          icon="store"
          :label="$t('method')"
          :title="$t('STORE')"
        >
          <!-- <template #obs>
            <span :class="{ fee: method.fee > 0 }">{{ fee(method) }}</span>
          </template> -->
        </we-card-icon>
        <div
          v-if="returns.store_return_details"
          class="option-description"
          v-html="returns.store_return_details"
        ></div>
      </div>
      <div
        v-if="returns.is_home_collection_return_enabled"
        class="option"
        :class="{ active: shippingSelected === 'HOME_COLLECTION' }"
        @click="shippingSelected = 'HOME_COLLECTION'"
      >
        <we-card-icon
          class="button"
          :class="{ active: shippingSelected === 'HOME_COLLECTION' }"
          icon="people-carry-box"
          :label="$t('method')"
          :title="$t('HOME_COLLECTION')"
        >
          <!-- <template #obs>
            <span :class="{ fee: method.fee > 0 }">{{ fee(method) }}</span>
          </template> -->
        </we-card-icon>
        <div
          v-if="returns.home_collection_return_details"
          class="option-description"
          v-html="returns.home_collection_return_details"
        ></div>
      </div>
    </div>
    <div class="bottom">
      <we-button
        class="primary"
        :text="$t('next')"
        icon="arrow-right"
        icon-position="right"
        @click="next"
      />
    </div>
  </section>
</template>

<script>
import ReturnAddress from "@/views/Returns/Address.vue";
export default {
  data() {
    return {
      addressOpened: false,
      shippingSelected: null
    };
  },
  components: {
    ReturnAddress
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    }
  },
  methods: {
    next() {
      if (!this.shippingSelected) {
        const snackBar = {
          title: "Não foi possível continuar",
          description: "Verifique se selecionou algum método de devolução.",
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        return;
      }

      this.$emit("next", this.shippingSelected);
    },
    fee(method) {
      return method.fee > 0
        ? `${this.$options.filters.valueToReais(method.fee)} ${this.$t("fee")}`
        : this.$t("no-fee");
    }
  }
};
</script>

<style lang="scss">
#shipping-step {
  .we-data-field {
    margin-bottom: 16px;
  }

  .fee {
    color: var(--red);
    font-weight: $semibold;
  }

  .options {
    .option {
      margin-bottom: 16px;
      &.active {
        .option-description {
          display: block;
        }
      }
      .option-description {
        display: none;
        padding-top: 16px;
        font-size: 14px;
        color: var(--text-2);

        p {
          margin-bottom: 8px;

          a {
            font-weight: $medium;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        ul,
        ol {
          margin-bottom: 8px;

          li {
            p {
              margin: 0;
            }
          }
        }

        ul {
          list-style: initial;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 8px;
        }

        h1,
        h2,
        h3 {
          margin-bottom: 8px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    .we-button {
      width: 100%;
      max-width: 160px;
    }
  }
}
</style>
