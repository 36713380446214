var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":_vm.direction === 'top',"right":_vm.direction === 'right',"bottom":_vm.direction === 'bottom',"left":_vm.direction === 'left',"disabled":!_vm.text || !_vm.isDesktop || _vm.disabled,"open-delay":_vm.delay,"content-class":"we-text-tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"we-text-tooltip",class:[
        _vm.componentClass,
        _vm.propsClass,
        { custom: _vm.lines != '1', disabled: _vm.disabled }
      ],style:(("max-width: " + _vm.maxWidth + "; -webkit-line-clamp: " + _vm.lines + ";")),on:{"click":function($event){return _vm.$emit('click')}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.text)+" ")])]}}])},[_vm._t("tooltip-content"),(!_vm.$slots['tooltip-content'])?_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText ? _vm.tooltipText : _vm.text)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }