<i18n>
{
  "en": {
    "options": "Options",
    "open-original": "Open original",
    "attach-image": "Attach image"
  },
  "pt-BR": {
    "options": "Opções",
    "open-original": "Abrir original",
    "attach-image": "Anexar imagem"
  }
}
</i18n>

<template>
  <div class="we-file-upload">
    <div class="name" v-if="title">
      {{ title }}
    </div>
    <div
      class="file"
      :style="{ height }"
      @click="$refs['we-file-upload-input'].click()"
    >
      <input
        type="file"
        id="upload"
        ref="we-file-upload-input"
        class="picture"
        :accept="accept"
        @change="uploadFromComputer"
      />
      <div class="file-actions" v-if="file">
        <we-balloon>
          <template #action>
            <we-icon-button icon="ellipsis-h" :name="$t('options')" />
          </template>
          <template #balloon>
            <a :href="file" target="_blank" rel="noopener" class="balloon-item">
              <font-awesome-icon icon="external-link-alt" />
              {{ $t("open-original") }}
            </a>
          </template>
        </we-balloon>
      </div>
      <div
        class="click-box"
        @click="$emit('click')"
        :style="{ padding }"
        @dragover="dragover"
        :class="{ dragovered }"
      >
        <div class="box-dragover" @dragleave="dragleave" @drop="drop">
          <div class="message">
            <font-awesome-icon icon="file-upload" />
            Solte para carregar o arquivo
          </div>
        </div>
        <div v-if="loading" class="message">
          <v-progress-circular
            indeterminate
            color="darken"
            size="18"
            width="3"
          ></v-progress-circular>
          Aguarde o arquivo carregar....
        </div>
        <img v-else-if="fileIsImage(file)" :src="file" :alt="title" />
        <div v-else-if="file" class="not-image">
          {{ file | fileExtension }}
        </div>
        <div v-else class="message">
          <font-awesome-icon icon="file-upload" />
          {{ uploadText ? uploadText : $t("attach-image") }}
        </div>
      </div>
    </div>
    <div class="upload-info">
      {{ info }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    file: String,
    info: String,
    loading: Boolean,
    uploadText: String,
    accept: {
      type: String,
      default: "image/*"
    },
    height: {
      type: String,
      default: "97px"
    },
    padding: String
  },
  data() {
    return {
      dragovered: false
    };
  },
  methods: {
    fileIsImage(file) {
      const extension = this.$options.filters.fileExtension(file);

      return ["png", "jpeg", "jpg", "gif", "webp", "svg", "ico"].includes(
        extension
      );
    },
    dragover(e) {
      e.preventDefault();
      if (!this.dragovered) {
        this.dragovered = true;
      }
    },
    dragleave(e) {
      e.preventDefault();
      if (this.dragovered) {
        this.dragovered = false;
      }
    },
    drop(event) {
      event.preventDefault();
      this.dragovered = false;

      const firstFile = event.dataTransfer?.files[0] || null;
      if (!firstFile) return;

      this.onChange(firstFile);
    },
    uploadFromComputer(e) {
      const file = e.target.files[0];
      this.onChange(file);
    },
    onChange(file) {
      this.$emit("change", file);
    }
  }
};
</script>

<style lang="scss">
.we-file-upload {
  input {
    display: none;
  }

  &.uploaded {
    .file {
      .click-box {
        border-color: var(--green);
        .message {
          color: var(--green);

          svg {
            color: var(--green);
          }
        }
      }
    }
  }

  .name {
    text-align: center;
    font-size: 14px;
    font-weight: $medium;
    color: var(--text-2);
    margin-bottom: 5px;
  }

  .file {
    height: 97px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden;

    &:hover {
      .file-actions {
        display: flex;
      }
    }

    .file-actions {
      width: 100%;
      display: none;
      justify-content: flex-end;
      z-index: 1;
      padding-top: 5px;
      padding-right: 5px;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;

      @include mobile {
        display: flex;
      }

      * {
        pointer-events: all;
      }

      .we-icon-button {
        @include mobile {
          background: var(--background-1);
          color: var(--text-2);
        }
      }
    }

    .click-box {
      background: var(--background-1);
      height: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      border: 2px dashed var(--action);

      &:hover {
        background: var(--action);
      }

      &.dragovered {
        .box-dragover {
          display: flex;
        }
      }

      .box-dragover {
        display: none;
        padding: 15px;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: -2px;
        left: -2px;
        align-items: center;
        justify-content: center;
        background: var(--primary-bg);
        z-index: 1;
        border: 2px dashed var(--primary);

        .message {
          color: var(--primary);
          pointer-events: none;

          svg {
            color: var(--primary);
          }
        }
      }

      .not-image {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: $semibold;
        color: var(--text-2);
        text-transform: uppercase;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }

    .message {
      font-size: 14px;
      font-weight: $medium;
      color: var(--text-2);
      display: flex;
      align-items: center;

      .v-progress-circular {
        margin-right: 18px;
        svg {
          margin: 0;
        }
      }

      svg {
        height: 18px;
        width: auto;
        color: var(--text-2);
        margin-right: 12px;
        position: relative;
        top: -1px;
      }
    }
  }

  .upload-info {
    text-align: center;
    font-size: 10px;
    color: var(--text-2);
  }
}
</style>
