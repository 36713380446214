import Vue from "vue";

export default {
  state: {
    order: null,
    orderInputs: null
  },
  getters: {
    getOrder: state => state.order,
    getOrderInputs: state => state.orderInputs
  },
  mutations: {
    setOrder: (state, payload) => {
      state.order = payload;
    },
    setOrderInputs: (state, payload) => {
      state.orderInputs = payload;
    }
  },
  actions: {
    orderRequest({ commit }, payload) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .get(`/plugins/shopify/public/order`, {
            params: { ...payload }
          })
          .then(resp => {
            let order = resp.data || [];

            commit("setOrder", order);
            resolve(resp);
          })
          .catch(err => {
            const snackBar = {
              title: "O pedido não foi encontrado!",
              description: ""
            };

            commit("setSnackBar", snackBar);
            commit("addSnackBar");
            resolve(err);
          });
      });
    }
  }
};
