<i18n>
{
  "en": {
    "order": "Order",
    "items": "Items",
    "shipping": "Shipping",
    "chargeback": "Chargeback",
    "return-website": "Go back to website",
    "return-website-mobile": "Go back",
    "terms": "Terms & Conditions",
    "privacy": "Privacy & Policy",
    "help": "Help",
    "created-by": "Created by"
  },
  "pt-BR": {
    "order": "Pedido",
    "items": "Itens",
    "shipping": "Devolução",
    "chargeback": "Estorno",
    "return-website": "Voltar para o site",
    "return-website-mobile": "Voltar",
    "terms": "Termos & Condições",
    "privacy": "Políticas de privacidade",
    "help": "Ajuda",
    "created-by": "Criado por"
  }
}
</i18n>

<template>
  <div id="default">
    <template v-if="!loading">
      <header class="default-header">
        <div class="container">
          <div class="logo">
            <img v-if="!returns.brand" src="@/assets/logo.svg" alt="Logo" />
            <img v-else :src="returns.brand" alt="Logo" />
          </div>
          <div class="links">
            <we-button
              v-if="returns.store_url"
              :text="$t('return-website')"
              :mobile-text="$t('return-website-mobile')"
              icon="arrow-left"
              class="inherit black mobile-icon"
              :href="returns.store_url"
            />
          </div>
        </div>
      </header>
      <div class="default-content">
        <div id="returns">
          <we-steps
            v-if="step <= 3"
            :steps="[
              $t('order'),
              $t('items'),
              $t('shipping'),
              $t('chargeback')
            ]"
            :active-step="step"
          />
          <order v-if="step === 0" @next="inputOrder" />
          <items v-if="step === 1" @next="selectItems" @prev="step--" />
          <shipping v-if="step === 2" @next="selectShipping" @prev="step--" />
          <chargeback
            v-if="step === 3"
            @next="selectChargeback"
            @prev="step--"
          />
          <completed v-if="step === 4" @next="step++" @prev="step--" />
        </div>
      </div>
      <footer class="default-footer">
        <div class="container">
          <div class="logo-and-links">
            <div class="logo">
              <img v-if="!returns.brand" src="@/assets/logo.svg" alt="Logo" />
              <img v-else :src="returns.brand" alt="Logo" />
            </div>
            <div class="links">
              <a
                class="link"
                v-if="returns.terms_of_service"
                :href="returns.terms_of_service"
                target="_blank"
              >
                {{ $t("terms") }}
              </a>
              <a
                class="link"
                v-if="returns.privacy_policies"
                :href="returns.privacy_policies"
                target="_blank"
              >
                {{ $t("privacy") }}
              </a>
              <a
                class="link"
                v-if="returns.help_center_url"
                :href="returns.help_center_url"
                target="_blank"
              >
                {{ $t("help") }}
              </a>
            </div>
          </div>
          <div class="created-by">
            {{ $t("created-by") }}
            <a class="link" href="https://wepipe.com.br" target="_blank">
              wepipe returns
            </a>
          </div>
        </div>
      </footer>
    </template>
    <we-loading-overflow :loading="loading" />
  </div>
</template>

<script>
import Order from "@/views/Returns/Order.vue";
import Items from "@/views/Returns/Items.vue";
import Shipping from "@/views/Returns/Shipping.vue";
import Chargeback from "@/views/Returns/Chargeback.vue";
import Completed from "@/views/Returns/Completed.vue";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import router from "../router";
import { mapActions } from "vuex";
export default {
  components: {
    Order,
    Items,
    Shipping,
    Chargeback,
    Completed
  },
  data() {
    return {
      step: 0,
      loading: true
    };
  },
  computed: {
    returns() {
      return this.$store.getters.getReturns;
    },
    routeParams() {
      return this.$store.getters.getRouteParams;
    }
  },
  methods: {
    ...mapActions(["returnsRequest"]),
    generateSessionId() {
      const todayEpoch = Date.now();
      this.$store.commit("setSessionId", todayEpoch);
    },
    inputOrder(event) {
      this.$store.commit("setOrderInputs", event);
      this.step++;
    },
    selectItems(event) {
      this.$store.commit("setSelectedItems", event);
      this.step++;
    },
    selectShipping(event) {
      this.$store.commit("setSelectedShipping", event);
      this.step++;
    },
    selectChargeback(event) {
      this.$store.commit("setSelectedChargeback", event);
      this.step++;
    }
  },
  async mounted() {
    this.$store.commit("setRouteParams", { ...this.$route.params });
    this.generateSessionId();

    const form = await this.returnsRequest(this.routeParams);

    if (form.response?.status >= 404) {
      this.$router.push({ name: "NotFound" });
      return;
    }

    if (form.data.favicon && form.data.favicon.trim().length >= 0) {
      const favicon = document.getElementById("favicon");
      favicon.href = form.data.favicon;
    }

    if (
      form.data.google_analytics &&
      form.data.google_analytics.trim().length >= 0
    ) {
      Vue.use(VueAnalytics, {
        id: `UA-${form.data.google_analytics}`,
        router
      });
    }

    this.loading = false;
  }
};
</script>

<style lang="scss">
#default {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  position: relative;

  .default-header {
    height: 70px;
    background: var(--background-1);
    border-bottom: 1px solid var(--line);

    @include mobile {
      height: 50px;
    }
    .container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        display: block;
        height: 30px;
        width: auto;

        @include mobile {
          height: 26px;
        }
      }
    }

    .links {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .default-content {
    flex: 1;
  }

  .default-footer {
    height: 70px;
    background: var(--background-2);

    @include mobile {
      height: fit-content;
      padding: 32px 0 4px 0;
    }
    .container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-flow: column;
        justify-content: center;
      }
    }

    .logo-and-links {
      display: flex;
      align-items: center;
      height: 100%;

      @include mobile {
        flex-flow: column;
      }

      .logo {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 50px;

        @include mobile {
          margin: 0;
          margin-bottom: 16px;
        }

        img {
          display: block;
          height: 30px;
          width: auto;
        }
      }

      .links {
        display: flex;
        height: 100%;
        align-items: center;

        @include mobile {
          flex-flow: column;
        }

        .link {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 12px;
          font-size: 14px;
          color: var(--text-1);
          font-weight: $medium;

          @include mobile {
            padding: 8px 12px;
            margin-bottom: 4px;
          }

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .created-by {
      font-size: 14px;
      color: var(--text-1);
      font-weight: $medium;

      @include mobile {
        color: var(--text-2);
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid var(--line);
        width: 100%;
        text-align: center;
      }
    }
  }
}
#returns {
  max-width: 475px;
  margin: 0 auto;
  padding: 146px 15px 80px 15px;

  @include sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .we-steps {
    margin-bottom: 32px;
  }

  .return-and-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .we-title {
      margin-left: 18px;
      margin-bottom: 0;
    }
  }

  .we-title {
    font-size: 24px;
    font-weight: $semibold;
    margin-bottom: 8px;
  }

  .we-description {
    font-size: 16px;
    color: var(--text-2);
    margin-bottom: 36px;
  }
}
</style>
