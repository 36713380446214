<i18n>
{
  "en": {
    "title": "Chargeback method",
    "method": "Method",
    "special-offer": "Special offer redeemed",
    "instant-credit": "Instant credit",
    "finish": "Finish",
    "REFUND": "Refund",
    "STORE_CREDIT": "Store credit",
    "next-error": "Wasn't possible to continue!",
    "next-error-description": "Check if you selected some chargeback method."
  },
  "pt-BR": {
    "title": "Método de estorno",
    "method": "Método de estorno",
    "special-offer": "Oferta especial aceita",
    "instant-credit": "Instantâneo",
    "finish": "Finalizar",
    "REFUND": "Reembolso",
    "STORE_CREDIT": "Crédito na loja",
    "next-error": "Não foi possível continuar!",
    "next-error-description": "Verifique se selecionou algum método de devolução."
  }
}
</i18n>

<template>
  <section id="chargeback-step">
    <special-offer
      v-if="specialOfferOpened"
      @close="
        specialOfferOpened = false;
        specialOfferBlocked = true;
      "
      @redeem="redeemSpecialOffer"
    />
    <div class="return-and-title">
      <we-icon-button icon="arrow-left" name="Voltar" @click="$emit('prev')" />
      <div class="we-title">
        {{ $t("title") }}
      </div>
    </div>
    <div class="options">
      <div
        class="option"
        :class="{ active: chargebackSelected === 'REFUND' }"
        @click="chargebackSelected = 'REFUND'"
        v-if="returns.is_cash_refund_enabled"
      >
        <we-card-icon
          class="button"
          :class="{ active: chargebackSelected === 'REFUND' }"
          icon="wallet"
          :label="$t('method')"
          :title="$t('REFUND')"
        />
        <div
          v-if="returns.cash_refund_details"
          class="option-description"
          v-html="returns.cash_refund_details"
        ></div>
      </div>
      <div
        class="option"
        :class="{ active: chargebackSelected === 'STORE_CREDIT' }"
        @click="chargebackSelected = 'STORE_CREDIT'"
        v-if="returns.is_credit_refund_enabled"
      >
        <we-card-icon
          class="button"
          :class="{ active: chargebackSelected === 'STORE_CREDIT' }"
          icon="gift"
          :label="$t('method')"
          :title="$t('STORE_CREDIT')"
        >
          <template #obs>
            <span v-if="returns.is_instant_credit_enabled" class="instant">
              <font-awesome-icon icon="bolt" /> {{ $t("instant-credit") }}
            </span>
          </template>
          <!-- <template #sublabel>
            <span class="green--text">
              {{ $t("special-offer") }}
            </span>
          </template> -->
        </we-card-icon>
        <div
          v-if="returns.credit_refund_details"
          class="option-description"
          v-html="returns.credit_refund_details"
        ></div>
      </div>
    </div>
    <div class="bottom">
      <we-button
        class="primary"
        :text="$t('finish')"
        icon="arrow-right"
        icon-position="right"
        :loading="loading"
        @click="next"
      />
    </div>
  </section>
</template>

<script>
import SpecialOffer from "@/views/Returns/SpecialOffer.vue";
import { mapActions } from "vuex";
export default {
  components: {
    SpecialOffer
  },
  data() {
    return {
      loading: false,
      specialOfferOpened: false,
      specialOfferRedeemed: false,
      specialOfferBlocked: false,
      chargebackSelected: null
    };
  },
  computed: {
    sessionId() {
      return this.$store.getters.getSessionId;
    },
    returns() {
      return this.$store.getters.getReturns;
    },
    orderInputs() {
      return this.$store.getters.getOrderInputs;
    },
    selectedItems() {
      return this.$store.getters.getSelectedItems;
    },
    selectedShipping() {
      return this.$store.getters.getSelectedShipping;
    },
    itemsPrice() {
      let price = 0;
      this.selectedItems.forEach(e => {
        price += e.price;
      });
      return price;
    }
  },
  methods: {
    ...mapActions(["createReturnsRequest"]),
    next() {
      if (!this.chargebackSelected) {
        const snackBar = {
          title: this.$t("next-error"),
          description: this.$t("next-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        return;
      }

      if (this.chargebackSelected === "STORE_CREDIT") {
        this.emitNext();
      }

      if (this.chargebackSelected === "REFUND") {
        this.openSpecialOffer();
      }
    },
    redeemSpecialOffer() {
      this.specialOfferRedeemed = true;
      this.chargebackSelected = "STORE_CREDIT";
      this.next();
    },
    openSpecialOffer() {
      if (
        !this.returns.is_special_offer_enabled ||
        Number(this.itemsPrice) > Number(this.returns.special_offer_limit) ||
        !this.returns.is_credit_refund_enabled
      ) {
        this.emitNext();
        return;
      }

      if (
        this.chargebackSelected === "REFUND" &&
        this.specialOfferBlocked === false
      ) {
        this.specialOfferOpened = true;
      } else {
        this.emitNext();
      }
    },
    async emitNext() {
      const filesUploadedIds =
        this.$store.getters.getFiles.map(e => e.id) || [];

      const payload = {
        email: this.orderInputs.email,
        order_id: this.orderInputs.order,
        products: this.selectedItems.map(e => {
          return {
            id: e.id,
            amount: e.quantity,
            tags: [e.return_reason]
          };
        }),
        return_method: this.selectedShipping,
        refund_method: this.chargebackSelected,
        form_files: filesUploadedIds,
        session_id: this.sessionId
      };

      this.loading = true;

      const create = await this.createReturnsRequest(payload);

      if (create.status === 200) {
        this.$emit("next", this.chargebackSelected);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
#chargeback-step {
  .we-data-field {
    margin-bottom: 16px;
  }

  .instant {
    color: var(--green);
    font-weight: $semibold;
  }

  .options {
    .option {
      margin-bottom: 16px;
      &.active {
        .option-description {
          display: block;
        }
      }
      .option-description {
        display: none;
        padding-top: 16px;
        font-size: 14px;
        color: var(--text-2);

        p {
          margin-bottom: 8px;

          a {
            font-weight: $medium;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        ul,
        ol {
          margin-bottom: 8px;

          li {
            p {
              margin: 0;
            }
          }
        }

        ul {
          list-style: initial;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 8px;
        }

        h1,
        h2,
        h3 {
          margin-bottom: 8px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    .we-button {
      width: 100%;
      max-width: 160px;
    }
  }
}
</style>
