<template>
  <div id="action_modal" :class="`columns-${columns}`">
    <div class="view">
      <transition appear name="fade">
        <we-shadow :class="{ scroll: shadowScroll }" @click="closeModal" />
      </transition>
      <transition appear name="slide-create">
        <slot name="view" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      shadowScroll: false
    };
  },
  methods: {
    closeModal() {
      let menuOpened = false;
      const menus = document.querySelectorAll(".v-menu__content");

      menus.forEach(e => {
        if (e.classList.contains("menuable__content__active")) {
          menuOpened = true;
        }
      });

      if (!menuOpened) {
        this.$emit("close");
      }
    },
    applyShadowSpacing() {
      const modal = document.querySelector(".modal");
      const screen = document.querySelector("body");

      if (screen.clientHeight < modal.clientHeight + 30) {
        this.shadowScroll = true;
      }
    }
  },
  mounted() {
    this.applyShadowSpacing();
  }
};
</script>

<style lang="scss">
#action_modal {
  position: absolute;
  .shadow {
    z-index: 6;

    &.scroll {
      width: calc(100% - 6px);
    }
  }

  &.columns-1 {
    .view {
      .modal {
        width: 362px;

        @include mobile {
          width: 100%;
        }

        .modal-body {
          .fields {
            grid-template-columns: minmax(0, 1fr);

            .email,
            .textarea {
              grid-column-start: auto;
              grid-column-end: auto;
            }
          }
        }
      }
    }
  }

  .view {
    position: fixed;
    background: var(--inherit);
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    padding: 15px;
    overflow-y: scroll;

    @include mobile {
      padding: 0;
      width: 100%;
      min-width: 100%;
    }

    .modal {
      margin: auto 0;
      height: fit-content;
      max-width: 562px;
      width: 100%;
      background: var(--background-1);
      border-radius: 10px;
      box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.25);
      z-index: 6;
      overflow: hidden;
      pointer-events: initial;
      position: relative;

      @include mobile {
        max-width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        display: grid;
        grid-template-rows: 50px minmax(0, 1fr) 50px;
      }

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        min-height: 65px;
        background: var(--background-2);
        border-bottom: 1px solid var(--line);
        position: relative;
        z-index: 3;
        padding: 0 14px;

        @include mobile {
          min-height: fit-content;
          height: 100%;
          padding-left: 15px;
          padding-right: 0;
        }

        &.big {
          display: block;
        }

        .options {
          margin: 0;
          display: flex;
          align-items: center;

          .we-icon-button {
            margin-left: 6px;
          }
        }

        .name {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: var(--text-1);

          @include mobile {
            font-size: 16px;
          }

          &.task-name {
            max-width: 400px;

            @include mobile {
              max-width: calc(100vw - 159px);
            }
          }

          span {
            color: var(--text-2);
            margin-right: 5px;
          }

          img {
            height: 20px;
            width: auto;
            margin-right: 5px;
          }

          .icon {
            min-width: 24px;
            width: 24px;
            display: flex;
            align-items: center;

            svg {
              height: 14px;
              width: auto;
              color: var(--text-1);
            }
          }
        }
      }

      .modal-body {
        padding: 20px 37px;
        padding-bottom: 0;

        @include mobile {
          padding: 20px 15px;
          padding-bottom: 140px;
          overflow-y: auto;
        }

        &.preview {
          background: var(--background-2);
          padding: 17px 37px;

          .we-label {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: $semibold;
            color: var(--text-2);
            margin-bottom: 16px;
            text-align: center;
          }

          .fields {
            margin-bottom: 0;

            .email,
            .textarea,
            .fill {
              grid-column-start: initial;
              grid-column-end: initial;
            }
            .double-column {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        }

        .we-block {
          margin-bottom: 16px;
        }

        .modal-img {
          max-width: 100%;
          height: 103px;
          width: auto;

          @include mobile {
            width: 100%;
            max-width: 150px;
            height: auto;
          }
        }

        .we-not-found {
          position: relative;
        }

        .fields {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
          column-gap: 16px;
          row-gap: 20px;
          margin-bottom: 20px;

          @include mobile {
            grid-template-columns: 1fr;
            column-gap: 0;
          }

          > div {
            @include mobile {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }

          .v-input {
            margin-bottom: 0;
          }

          .double-column {
            grid-column-start: 1;
            grid-column-end: 3;
          }

          .single-column {
            grid-column-start: initial;
            grid-column-end: initial;
          }

          .dynamic-fields {
            padding-left: 0;
            display: grid;
            grid-template-columns: 160px 120px 1fr 40px;
            align-items: flex-end;
            gap: 16px;
            margin-bottom: 14px;

            &.only-two {
              grid-template-columns: 1fr 1fr 40px;
              margin-bottom: 0;
            }

            &:first-child {
              .we-icon-button {
                display: none;
              }
            }

            .we-input.pagination .label {
              padding-left: 13px;
            }
          }

          .divider {
            font-size: 16px;
            color: var(--text-2);
            font-weight: $semibold;
            margin-left: -22px;
            display: flex;
            align-items: center;
            .icon {
              width: 24px;
              display: flex;
              align-items: center;

              svg {
                color: var(--text-2);
                height: 14px;
                width: auto;
              }
            }
          }
        }

        .checkbox {
          margin-bottom: 20px;
          .v-input {
            padding: 0;
            margin: 0;
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .bottom {
        position: relative;
        width: 100%;
        bottom: 0;
        height: 65px;
        border-top: 1px solid var(--line);
        background: var(--background-2);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;

        @include mobile {
          height: 50px;
          position: fixed;
          left: 0;
        }

        &.double-action {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
        }

        &.has-history {
          justify-content: space-between;
          padding: 0 14px;
          button {
            min-width: 176px;

            @include mobile {
              min-width: auto;
            }
          }

          .history {
            height: 50px;
            display: flex;
            align-items: center;

            .icon {
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 13px;
              svg {
                width: 18px;
                height: auto;
                color: var(--text-2);
              }
            }

            .data {
              .item {
                color: var(--text-2);
                font-size: 12px;
                font-weight: $regular;

                @include mobile {
                  font-size: 10px;
                }

                span {
                  font-weight: $semibold;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
