<template>
  <div class="shadow" @click="$emit('click')"></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
}
</style>
