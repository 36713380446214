<template>
  <div class="we-product-icon">
    <font-awesome-icon
      :class="color + '--text'"
      icon="cube"
      :style="{ height: size + 'px', width: size + 'px' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    size: String
  }
};
</script>

<style lang="scss">
.we-product-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}
</style>
